import Image from 'next/image';
import Link from 'next/link';

import {
  HostEventStatistics,
  LetsPlanFirstEvent
} from '@/components/atomic/atoms';
import { Text, TextWithIcon } from '@/components/atomic/nuclei';
import {
  HOST_INTERACTION_STATUS,
  staticMediaStoreBaseURL
} from '@/config/common';
import { getAvatarText, parseNumberedLabel } from '@/lib/utils';
import { getHostTouchPoints } from '@/services/hostEvent.service';
import { PAGE_NAME, getPageURL } from '@/services/plannerPortal.service';

const Avatar = ({ name }) => {
  const avatarText = getAvatarText({ name });

  return (
    <div className='w-[100px] h-[100px] text-[56px] rounded-lg bg-brand-gradient flex items-center justify-center font-semibold'>
      <span className='text-white'>{avatarText}</span>
    </div>
  );
};

const CommonTextWithIcon = ({ icon, label }) => {
  const show = Boolean(label);
  return (
    <div className={`mt-4 ${show ? 'flex' : 'hidden'}`}>
      <TextWithIcon
        {...{
          alt: icon,
          className: 'gap-2 border border-neutral rounded-lg py-1 px-2',
          icon,
          iconHeight: 24,
          iconWidth: 24,
          label,
          labelStyle: 'font-medium text-dim-gray',
          show
        }}
      />
    </div>
  );
};

const HostProfileEventListSidebar = ({
  user: {
    companyName,
    createdAt,
    customerSegment,
    email,
    ethnicity,
    hostNumber,
    id,
    interactionStatus,
    mobile,
    name,
    preferredLanguage
  },
  hostEventStatistics
}) => {
  const hostTouchPoints = getHostTouchPoints({
    email,
    mobile,
    requesterType: customerSegment?.name
  });
  const showLetsPlanFirstEvent =
    interactionStatus === HOST_INTERACTION_STATUS.NEW.value;

  return (
    <div className='fixed w-108 h-full left-0'>
      <div className='bg-white h-[95vh] px-8 py-4'>
        <div className='flex items-center gap-4'>
          <div className='w-[100px] h-[100px] rounded-lg my-4'>
            <Avatar {...{ name }} />
          </div>
          <div className='flex flex-col'>
            <Text
              {...{
                className: 'text-brand text-xl font-semibold',
                content: name
              }}
            />
            {companyName && (
              <Text
                {...{
                  className: 'text-nero font-medium my-2',
                  content: companyName
                }}
              />
            )}
          </div>
        </div>

        <div className='flex justify-between mt-1'>
          <Link
            href={getPageURL({
              pageName: PAGE_NAME.HOST_PROFILE.label,
              pathParams: {
                userId: id
              }
            })}
          >
            <span className='flex items-center gap-2 border border-brand py-1 rounded-lg cursor-pointer px-2'>
              <Image
                alt='eye'
                height={0}
                src={`${staticMediaStoreBaseURL}/icons/view-red.svg`}
                style={{ height: 16, width: 16 }}
                width={0}
              />
              <Text
                {...{
                  className: 'text-sm font-medium text-brand',
                  content: 'Profile'
                }}
              />
            </span>
          </Link>
          <div className='flex gap-4'>
            <Text
              {...{
                className: `text-sm font-medium px-2 py-1 rounded-lg ${HOST_INTERACTION_STATUS[interactionStatus]?.className}`,
                content: HOST_INTERACTION_STATUS[interactionStatus]?.label
              }}
            />
            <TextWithIcon
              {...{
                alt: 'number',
                className: 'rounded-lg border border-neutral gap-1 px-2 py-1',
                icon: 'number-icon.svg',
                iconHeight: 20,
                iconWidth: 20,
                label: parseNumberedLabel(hostNumber),
                labelStyle: 'text-dim-gray text-sm font-medium'
              }}
            />
          </div>
        </div>
        {hostTouchPoints.map(
          (
            {
              alt,
              className,
              icon,
              iconHeight,
              iconWidth,
              label,
              labelStyle,
              show
            },
            index
          ) => (
            <TextWithIcon
              key={index}
              {...{
                alt,
                className,
                icon,
                iconHeight,
                iconWidth,
                label,
                labelStyle,
                show
              }}
            />
          )
        )}

        <div className='flex gap-4'>
          <CommonTextWithIcon
            {...{
              icon: 'country-icon.svg',
              label: ethnicity?.name
            }}
          />
          <CommonTextWithIcon
            {...{
              icon: 'language-icon.svg',
              label: preferredLanguage?.name
            }}
          />
        </div>

        <div className='flex justify-center fixed bottom-8 w-[27rem] h-[280px]'>
          {showLetsPlanFirstEvent ? (
            <LetsPlanFirstEvent
              {...{
                createdAt,
                labelClassName: 'text-brand'
              }}
            />
          ) : (
            <HostEventStatistics
              {...{
                createdAt,
                hostEventStatistics
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default HostProfileEventListSidebar;
