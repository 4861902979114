import Image from 'next/image';
import { useState } from 'react';
import {
  ClearRefinements,
  CurrentRefinements,
  Hits,
  Pagination,
  useCurrentRefinements,
  useStats
} from 'react-instantsearch';

import { NoResultsBoundary, PageNotFound } from '@/components/atomic/atoms';
import SortResultsModal from '@/components/atomic/atoms/searchkit/SortResultsModal';
import {
  ProductCard,
  ProductListFilters,
  ProductSearchBar,
  ToastContainer
} from '@/components/atomic/molecules';
import { Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { PRODUCTS_SORT_OPTIONS } from '@/config/searchKit';
import transformCurrentRefinementItemsLabel from '@/helpers/searchkit';

const currentRefinementClassGroup = {
  category: 'pl-1 pr-2 py-2 rounded-md flex !ml-0 text-xs font-light',
  delete: '!ml-2 p-0 self-center searchkit-filter-delete-icon',
  item: 'bg-white border-brand px-2 rounded-md flex flex-wrap gap-2 searchkit-selected-filters',
  label: 'font-medium text-nero text-sm',
  list: '!flex flex-wrap !gap-2 !mb-0'
};

const SearchResultsHeader = ({ resultsCount, searchQuery }) => (
  <div className='font-medium text-xl flex gap-2'>
    <div className='text-brand'>{resultsCount} Products</div>
    {searchQuery && <div> - {searchQuery}</div>}
  </div>
);

const ProductListingOrganism = ({
  includedAttributes,
  searchableAttribute,
  setSearchableAttribute,
  setAddItemToNewCartRequest
}) => {
  const { nbHits: resultsCount, query: searchQuery } = useStats();
  const { items: currentRefinements } = useCurrentRefinements();
  const [showFilters, setShowFilters] = useState(true);
  const [openedCardId, setOpenedCardId] = useState(null);
  const [showToast, setShowToast] = useState({
    message: null,
    show: false,
    status: false
  });
  return (
    <div className='!mt-4 container mx-auto'>
      <div className='flex gap-6 searchkit-filter-container sticky z-10 top-0 bg-white left-0 py-2'>
        <div className={`self-top w-[21rem]`}>
          <div className='flex mt-1 items-center justify-between'>
            <div
              onClick={() => setShowFilters(!showFilters)}
              className='flex gap-2 cursor-pointer px-3 py-1 bg-brand-gradient rounded-r-lg'
            >
              <div className='self-center'>
                <Image
                  alt='view-icon'
                  height={0}
                  src={`${staticMediaStoreBaseURL}/icons/filter-icon.svg`}
                  style={{ height: 20, width: 20 }}
                  width={0}
                />
              </div>
              <Text
                {...{
                  content: 'Filters',
                  className: 'text-base text-white font-light'
                }}
              />
              <div
                className='self-center'
                onClick={() => setShowFilters(!showFilters)}
              >
                <Image
                  alt='view-icon'
                  className='cursor-pointer'
                  height={0}
                  src={`${staticMediaStoreBaseURL}/icons/${showFilters ? 'view-white-icon.svg' : 'hide-icon.svg'}`}
                  style={{ height: 20, width: 20 }}
                  width={0}
                />
              </div>
            </div>
            {showFilters && (
              <ClearRefinements
                {...{
                  classNames: {
                    button: 'searchkit-clear-all-button',
                    root: 'searchkit-clear-all-button-container items-center'
                  },
                  includedAttributes,
                  translations: {
                    resetButtonText: 'Clear All'
                  }
                }}
              />
            )}
          </div>
          {showFilters && (
            <div className='border-t border-platinum mt-3 mb-4'></div>
          )}
        </div>

        <div className='flex flex-col flex-1 pr-10 product-search-query'>
          <div className='flex items-center justify-between'>
            <ProductSearchBar
              {...{
                animationContainerStyle: 'top-3',
                className:
                  'flex relative -top-5 mt-4 list-page-search-container',
                searchableAttribute,
                searchBoxWidth: 'w-[33rem]',
                setSearchableAttribute,
                searchBtnStyle: 'hidden',
                showSearchBarSection: true
              }}
            />

            <SortResultsModal
              {...{
                items: PRODUCTS_SORT_OPTIONS
              }}
            />
          </div>
        </div>
      </div>

      <div
        className={`flex gap-6 ${showFilters ? 'h-[210rem]' : 'h-[165rem]'}`}
      >
        <ProductListFilters {...{ showFilters }} />
        <div className='flex flex-col px-2 w-full h-screen relative'>
          <SearchResultsHeader {...{ resultsCount, searchQuery }} />

          <div className='w-full'>
            <div
              className={`${currentRefinements.length > 0 ? 'mb-4 mt-4' : 'my-0'}`}
            >
              <CurrentRefinements
                {...{
                  classNames: {
                    ...currentRefinementClassGroup
                  },
                  excludedAttributes: ['query'],
                  transformItems: transformCurrentRefinementItemsLabel
                }}
              />
            </div>
            <NoResultsBoundary
              fallback={
                <PageNotFound
                  {...{
                    className: 'h-auto my-20',
                    description: `Sorry, we couldn’t find any matches for ${searchQuery}`,
                    iconHeight: 338,
                    iconWidth: 624,
                    notFoundImage: 'not-found.svg',
                    title: 'No search result'
                  }}
                />
              }
            >
              <Hits
                {...{
                  classNames: {
                    ...{
                      disabledLoadMore: 'hidden',
                      item: 'p-0 border-none shadow-none searchkit-product-item',
                      list: 'mt-4 flex gap-6 flex-wrap searchkit-product-list',
                      loadMore:
                        'text-white bg-brand-gradient border-none shadow-card searchkit-load-more-button'
                    }
                  },
                  hitComponent: (hit) => (
                    <ProductCard
                      {...{
                        ...hit,
                        openedCardId,
                        setOpenedCardId,
                        setAddItemToNewCartRequest,
                        setShowToast
                      }}
                    />
                  )
                }}
              />
            </NoResultsBoundary>
            <Pagination
              {...{
                classNames: {
                  disabledItem: 'disabledItem',
                  firstPageItem: 'firstPageItem',
                  item: 'item',
                  lastPageItem: 'lastPageItem',
                  link: 'searchkit-pagination-link',
                  list: 'flex justify-center gap-2 md:gap-6 mb-10 mt-4',
                  nextPageItem: 'searchkit-pagination-next-page w-28',
                  noRefinementRoot: 'hidden',
                  pageItem: 'pageItem',
                  previousPageItem: 'searchkit-pagination-previous-page w-28',
                  root: 'searchkit-pagination mt-4',
                  selectedItem: 'searchkit-pagination-selected-link'
                },
                padding: 1,
                showFirst: false,
                showLast: false,
                translations: {
                  nextPageItemText: 'Next',
                  previousPageItemText: 'Previous'
                }
              }}
            />
          </div>
          <ToastContainer
            {...{
              setShowToast,
              showToast
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductListingOrganism;
