import Image from 'next/image';

import { EPICBrief } from '@/components/atomic/atoms';
import {
  Text,
  TextKeyValuePair,
  TextWithIcon
} from '@/components/atomic/nuclei';
import {
  ALLOCATION_STATUS,
  mediaStoreBaseURL,
  staticMediaStoreBaseURL,
  USER_EVENT_STAGE
} from '@/config/common';
import { parseNumberedLabel } from '@/lib/utils';
import {
  getLabelForEventRequestIsEndToEnd,
  getZendeskTicketPriorityConfig
} from '@/services/eventCartDetail.service';

const HostEventProfileSidebar = ({
  eventRequestIsEndToEnd,
  setShowToast,
  userEventInformation
}) => {
  const {
    userEventDetails: [
      { allocationStatus, checkoutEvent = {}, opsUser = {} } = {}
    ] = [],
    stage,
    userEventNumber,
    zendeskTicketDetail
  } = userEventInformation;
  const { priority } = zendeskTicketDetail || {};
  const showIsEventRequestEndToEndLabel = eventRequestIsEndToEnd !== null;
  const zendeskTicketPriorityConfig = getZendeskTicketPriorityConfig(priority);
  return (
    <div className='md:w-108 fixed h-screen z-30 bg-host-event-sidebar bg-no-repeat bg-cover px-6 py-4 md:pl-10 md:pr-10 md:pt-5 flex flex-col gap-4'>
      <Image
        alt='hafla-logo'
        className='rounded-lg'
        height={0}
        priority={true}
        src={`${staticMediaStoreBaseURL}/icons/hafla-white-logo.svg`}
        style={{
          height: 40,
          width: 125
        }}
        width={0}
      />
      <div className='flex gap-4 items-center mt-4'>
        <div className='w-[100px] h-[100px] rounded-lg '>
          <Image
            alt='Event'
            className='rounded-lg'
            height={0}
            priority={true}
            src={`${mediaStoreBaseURL}/${checkoutEvent?.iconUrl}`}
            style={{
              height: 100,
              width: 100
            }}
            unoptimized={true}
            width={0}
          />
        </div>
        <div className='flex flex-col gap-1'>
          <Text
            {...{
              className:
                'text-nero text-xl font-semibold text-white w-72 truncate-text',
              content: checkoutEvent?.event.name
            }}
          />
          {showIsEventRequestEndToEndLabel && (
            <Text
              {...{
                className: 'text-base font-medium text-white',
                content: getLabelForEventRequestIsEndToEnd(
                  eventRequestIsEndToEnd
                )
              }}
            />
          )}
        </div>
      </div>
      <div className='flex justify-between'>
        <div>
          <Text
            {...{
              className: `${USER_EVENT_STAGE[stage].class} rounded-lg px-2 py-1 text-sm font-medium`,
              content: USER_EVENT_STAGE[stage].label
            }}
          />
        </div>
        <div className='flex gap-2'>
          <TextWithIcon
            {...{
              className: `${zendeskTicketPriorityConfig.className} px-2 py-1`,
              icon: zendeskTicketPriorityConfig.icon,
              label: priority,
              labelStyle: 'text-sm font-medium',
              show: Boolean(priority)
            }}
          />
          <TextWithIcon
            {...{
              className: 'bg-white rounded-lg px-3 py-1',
              icon: 'event-red-icon.svg',
              label: parseNumberedLabel(userEventNumber),
              labelStyle: 'text-sm font-medium text-dim-gray'
            }}
          />
        </div>
      </div>
      <div className='flex justify-start gap-4 mt-2'>
        <div className='flex gap-3 items-center'>
          <Image
            alt='Event'
            className='rounded-lg'
            height={0}
            priority={true}
            src={`${staticMediaStoreBaseURL}/icons/planner-icon.svg`}
            style={{
              height: 48,
              width: 48
            }}
            width={0}
          />
          <TextKeyValuePair
            {...{
              className: 'flex-col !gap-0',
              label: opsUser?.name || 'NA',
              labelClass: 'text-white font-semibold text-lg',
              spaceTop: '',
              value: ALLOCATION_STATUS[allocationStatus].label,
              valueClassName: 'text-white font-medium text-sm'
            }}
          />
        </div>
      </div>
      <div className='my-2 border-t border-opacity-color'></div>
      <EPICBrief
        {...{
          containerStyle: 'event-progress-bar-content',
          setShowToast,
          userEvent: userEventInformation
        }}
      />
    </div>
  );
};

export default HostEventProfileSidebar;
