import Image from 'next/image';
import { memo, useMemo } from 'react';
import { SearchBox, useSearchBox } from 'react-instantsearch';

import { ScopeSearchAttributeModal } from '@/components/atomic/atoms';
import { Button } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import {
  GENERIC_ALL_TAB_CATEGORY_MAP,
  NAVIGATION_SEARCHABLE_ATTRIBUTE_MAP
} from '@/config/searchKit';

const NavigationSearchBar = memo(
  ({
    className = 'flex mx-auto relative -top-3',
    query,
    searchableAttribute,
    searchBtnStyle = '',
    selectedTabMenu,
    setQuery,
    setSearchableAttribute,
    setShowResults
  }) => {
    const activeTabMenuCategory =
      selectedTabMenu || GENERIC_ALL_TAB_CATEGORY_MAP.value;
    const searchAttributeLabelMap = useMemo(
      () => NAVIGATION_SEARCHABLE_ATTRIBUTE_MAP[activeTabMenuCategory],
      [activeTabMenuCategory]
    );

    const { refine } = useSearchBox();

    const disableSearchButton = query.length < 3;

    const handleSearchChange = (event) => {
      setQuery(event.target.value);
    };

    const onSearchQuery = () => {
      refine(query);
      setShowResults(true);
    };

    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (!disableSearchButton) {
          setTimeout(() => {
            onSearchQuery();
          }, 1000);
        }
      }
    };

    const selectedAttributeLabel =
      Object.values(searchAttributeLabelMap).find(
        ({ key }) => key === searchableAttribute
      )?.label || Object.values(searchAttributeLabelMap)[0].label;

    return (
      <div className='mt-3'>
        <div className={className}>
          <ScopeSearchAttributeModal
            {...{
              searchableAttribute,
              searchAttributeLabelMap,
              selectedAttributeLabel,
              setSearchableAttribute
            }}
          />
          <div
            className='flex'
            onChange={handleSearchChange}
          >
            <SearchBox
              {...{
                loadingIconComponent: () => <div className='hidden'></div>,
                onKeyPressCapture: handleKeyPress,
                classNames: {
                  form: 'before:bg-searchkit-search-box-form',
                  input:
                    'h-[56px] w-[58.6rem] rounded-r-full rounded-l-none shadow-navigate-cart border-neutral text-base text-nero search-input-box'
                },
                placeholder: 'Start Typing...',
                searchAsYouType: false,
                onResetCapture: () => {
                  setQuery('');
                  setShowResults(false);
                },
                resetIconComponent: ({ classNames }) => (
                  <div className={classNames.resetIcon}>
                    <Image
                      {...{
                        alt: 'clear search',
                        height: 0,
                        src: `${staticMediaStoreBaseURL}/icons/clear-searchbox-icon.svg`,
                        style: { height: 12, width: 12 },
                        width: 0
                      }}
                    />
                  </div>
                ),
                submitIconComponent: () => (
                  <div className='absolute left-3/4 top-2'></div>
                )
              }}
            />
            <div className={`absolute right-4 ${searchBtnStyle}`}>
              <Button
                {...{
                  className:
                    'bg-brand-gradient rounded-full text-white text-base font-medium self-center py-2 w-24 mt-2',
                  disabled: disableSearchButton,

                  label: 'Search',
                  onClick: onSearchQuery,
                  width: 'w-24'
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

NavigationSearchBar.displayName = 'NavigationSearchBar';

export default NavigationSearchBar;
