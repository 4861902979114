import {
  EPMFormRow,
  ShowFormattedAddress,
  TimePickerWithLabel
} from '@/components/atomic/atoms';
import {
  InputField,
  SelectTags,
  Text,
  TextArea
} from '@/components/atomic/nuclei';
import { TAG_OPTIONS_SELECTION_MODE } from '@/config/common';
import { checkIsEmptyOrDummyEventDetail } from '@/services/eventCartDetail.service';
import { EVENT_PROFILE_FORM_STEPS } from '@/services/hostEvent.service';

const getEventDuration = ({
  errors,
  isUserEventDetailInEditMode,
  register
}) => [
  {
    className: 'rounded-lg pl-10',
    dbName: 'eventDurationInDays',
    disabled: !isUserEventDetailInEditMode,
    errors,
    iconURL: isUserEventDetailInEditMode
      ? 'time-icon-red.svg'
      : 'time-icon-black.svg',
    inputGroup: 'flex flex-col gap-2 flex-1',
    label: 'Event Duration (in days)',
    labelClass: 'text-nero text-base font-medium',
    placeholder: 'Enter Event Duration (in days)',
    register: { ...register('eventDurationInDays') },
    type: 'number'
  }
];

const getBudgetAndOpportunity = ({
  errors,
  isUserEventDetailInEditMode,
  register
}) => [
  {
    className: 'rounded-lg pl-10',
    dbName: 'budget',
    disabled: !isUserEventDetailInEditMode,
    errors,
    iconURL: isUserEventDetailInEditMode
      ? 'budget-icon-red.svg'
      : 'budget-icon-black.svg',
    inputGroup: 'flex flex-col gap-2 flex-1',
    label: 'Budget',
    labelClass: 'text-nero text-base font-medium',
    placeholder: 'Enter Budget',
    register: { ...register('budget') }
  },
  {
    className: 'rounded-lg pl-10',
    dbName: 'opportunityValue',
    disabled: !isUserEventDetailInEditMode,
    errors,
    iconURL: isUserEventDetailInEditMode
      ? 'budget-icon-red.svg'
      : 'budget-icon-black.svg',
    inputGroup: 'flex flex-col gap-2 flex-1',
    label: 'Opportunity Value',
    labelClass: 'text-nero text-base font-medium',
    placeholder: 'Enter Opportunity Value',
    register: { ...register('opportunityValue') }
  }
];

const getTheme = ({ errors, isUserEventDetailInEditMode, register }) => [
  {
    className: 'rounded-lg pl-10',
    dbName: 'theme',
    disabled: !isUserEventDetailInEditMode,
    errors,
    iconURL: isUserEventDetailInEditMode
      ? 'theme-icon-red.svg'
      : 'theme-icon-black.svg',
    inputGroup: 'flex flex-col gap-2 flex-1',
    label: 'Theme',
    labelClass: 'text-nero text-base font-medium',
    placeholder: 'Enter Theme',
    register: { ...register('theme') }
  }
];

const EventDeepDiveStep = ({
  contactMethods,
  errors,
  eventFormDetails,
  eventSiteTypeList,
  formActiveStepId,
  getValues,
  isUserEventDetailInEditMode,
  register,
  selectedEventContactMethod,
  setEventFormDetails,
  setValue
}) => {
  const { selectedEventSiteType, selectedEventStartTime, userAddress } =
    eventFormDetails || {};

  const getErrorClass = ({ key }) =>
    checkIsEmptyOrDummyEventDetail({
      key,
      value: getValues(key)
    })
      ? '!text-error-base'
      : '';
  const formStepVisibilityClass =
    formActiveStepId === EVENT_PROFILE_FORM_STEPS.EVENT_DEEP_DIVE.id
      ? 'block'
      : 'hidden';

  return (
    <div
      className={formStepVisibilityClass}
      id={EVENT_PROFILE_FORM_STEPS.EVENT_DEEP_DIVE.id}
    >
      <div className='flex flex-col gap-4 px-8'>
        <div className='flex flex-col gap-2'>
          <Text
            {...{
              className: 'text-nero text-base font-medium',
              content: 'How did you reach the customer?'
            }}
          />
          <SelectTags
            {...{
              isEditable: isUserEventDetailInEditMode,
              selectedTagOptions: selectedEventContactMethod,
              setSelectedTagOptions: (newContactMethod) =>
                setEventFormDetails((prevState) => ({
                  ...prevState,
                  selectedEventContactMethod: newContactMethod
                })),
              tagOptions: contactMethods,
              tagOptionsSelectionMode: TAG_OPTIONS_SELECTION_MODE.SINGLE
            }}
          />
        </div>
        <InputField
          {...{
            className: `rounded-lg pl-10 ${getErrorClass({ key: 'eventTitle' })}`,
            dbName: 'eventTitle',
            disabled: !isUserEventDetailInEditMode,
            errors,
            iconURL: isUserEventDetailInEditMode
              ? 'event-red-icon.svg'
              : 'event-icon-black.svg',
            inputGroup: 'flex flex-col gap-2',
            label: 'Event Title',
            labelClass: 'text-nero text-base font-medium',
            placeholder: 'Enter Event Title',
            register: { ...register('eventTitle') }
          }}
        />
        <ShowFormattedAddress
          {...{
            disabled: !isUserEventDetailInEditMode,
            errors,
            setShowAddAddress: (address) =>
              setEventFormDetails((prevState) => ({
                ...prevState,
                showAddAddress: address
              })),
            iconURL: isUserEventDetailInEditMode
              ? 'location-icon-with-frame.svg'
              : 'location-icon-black.svg',
            setValue,
            userAddress
          }}
        />
        <EPMFormRow
          {...{
            inputProps: getEventDuration({
              errors,
              isUserEventDetailInEditMode,
              register
            })
          }}
        />

        <EPMFormRow
          {...{
            inputProps: getBudgetAndOpportunity({
              errors,
              isUserEventDetailInEditMode,
              register
            })
          }}
        />

        <div className='flex gap-4 w-full'>
          <TimePickerWithLabel
            {...{
              errors,
              eventFormDetails,
              isUserEventDetailInEditMode,
              selectedEventStartTime,
              setEventFormDetails
            }}
          />
          <EPMFormRow
            {...{
              inputProps: getTheme({
                errors,
                isUserEventDetailInEditMode,
                register
              })
            }}
          />
        </div>
        <div className='flex flex-col gap-2'>
          <Text
            {...{
              className: 'text-nero text-base font-medium',
              content: 'Event Site'
            }}
          />
          <SelectTags
            {...{
              isEditable: isUserEventDetailInEditMode,
              selectedTagOptions: selectedEventSiteType,
              setSelectedTagOptions: (newSiteType) =>
                setEventFormDetails((prevState) => ({
                  ...prevState,
                  selectedEventSiteType: newSiteType
                })),
              tagOptions: eventSiteTypeList,
              tagOptionsSelectionMode: TAG_OPTIONS_SELECTION_MODE.SINGLE
            }}
          />
        </div>
        <TextArea
          {...{
            className: 'rounded-lg pl-10 h-24',
            dbName: 'additionalDetails',
            disabled: !isUserEventDetailInEditMode,
            errors,
            iconURL: isUserEventDetailInEditMode
              ? 'solution-design-icon-red.svg'
              : 'solution-design-icon-black.svg',
            inputGroup: 'flex flex-col gap-2 flex-1',
            label: 'Solution Design Notes',
            labelClass: 'text-nero text-base font-medium',
            placeholder: 'Enter Solution Design Notes',
            register: { ...register('additionalDetails') }
          }}
        />
      </div>
    </div>
  );
};

export default EventDeepDiveStep;
