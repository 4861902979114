import _ from 'lodash';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';

import {
  HostProfileStepOne,
  HostProfileStepTwo,
  StepFormHeader,
  ViewEditCTAPair
} from '@/components/atomic/atoms';
import { HOST_NAME_VALIDATION_CONFIG } from '@/config/common';
import useYupValidator from '@/lib/yupValidator';
import {
  fetchStaticAPIs,
  hostProfileStepFieldErrorConfig,
  hostProfileTabs,
  updateHostProfile
} from '@/services/hostProfile.service';

const { nameRegex, nameInputError } = HOST_NAME_VALIDATION_CONFIG;

const HostProfileHeader = ({
  fieldsErrorConfig,
  errors,
  formActiveStepId,
  onExitClick,
  setFormActiveStepId,
  showCloseIcon
}) => (
  <StepFormHeader
    {...{
      fieldsErrorConfig,
      errors,
      formActiveStepId,
      onExitClick,
      setFormActiveStepId,
      showCloseIcon,
      tabList: Object.values(hostProfileTabs)
    }}
  />
);

const ViewEditHostProfile = ({
  hostProfile: { user },
  setHostProfile,
  setLoading,
  setShowToast,
  showToast
}) => {
  const router = useRouter();
  const [isEditable, setIsEditable] = useState(false);
  const [formActiveStepId, setFormActiveStepId] = useState(
    Object.values(hostProfileTabs)[0].id
  );

  const [hostProfileMetadata, setHostProfileMetadata] = useState({
    ethnicityList: [],
    hostTypeList: [],
    preferredLanguageList: []
  });

  useEffect(() => {
    fetchStaticAPIs({
      setHostProfileMetadata,
      setShowToast
    });
  }, []);

  const yupResolver = useYupValidator(
    yup.object().shape({
      name: yup
        .string()
        .trim()
        .matches(nameRegex, nameInputError)
        .required('Host Name is required.')
    })
  );

  const {
    clearErrors,
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    watch
  } = useForm({
    resolver: yupResolver,
    values: user
  });

  const { errors } = useFormState({
    control
  });

  const { interactionStatus, hostNumber } = user;

  const onExitClick = () => {
    router.back();
  };

  const onSubmit = (dataToUpdate) =>
    updateHostProfile({
      dataToUpdate,
      setHostProfile,
      setLoading,
      setShowToast
    });

  const onSubmitHandler = isEditable
    ? handleSubmit(onSubmit)
    : () => setIsEditable(true);

  const notificationConfig = {
    errors,
    toastConfig: {
      ...showToast,
      show: showToast.show || !_.isEmpty(errors)
    }
  };

  return (
    <div
      id={hostProfileTabs.HOST_PROFILE.id}
      className='bg-white mb-7 px-6 pb-10'
    >
      <HostProfileHeader
        {...{
          errors,
          fieldsErrorConfig: hostProfileStepFieldErrorConfig,
          formActiveStepId,
          hostNumber,
          interactionStatus,
          onExitClick,
          setFormActiveStepId,
          showCloseIcon: !isEditable
        }}
      />

      <HostProfileStepOne
        {...{
          errors,
          formActiveStepId,
          hostProfileMetadata,
          isEditable,
          register,
          setValue,
          watch
        }}
      />

      <HostProfileStepTwo
        {...{
          formActiveStepId,
          hostProfileMetadata,
          isEditable,
          register,
          setValue,
          watch
        }}
      />

      <ViewEditCTAPair
        {...{
          backward: {
            onClick: () => {
              if (isEditable) {
                setIsEditable(false);
                clearErrors();
                reset();
              } else {
                onExitClick();
              }
            }
          },
          forward: {
            onClick: onSubmitHandler
          },
          notificationConfig,
          type: isEditable ? 'editCTAPair' : 'viewCTAPair'
        }}
      />
    </div>
  );
};

export default ViewEditHostProfile;
