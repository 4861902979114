import { useRouter } from 'next/router';

import {
  ConfirmationModal,
  PDFDownloadConfirmationModal,
  UploadCartItemMediaModal
} from '@/components/atomic/atoms';
import { BookOrderModal } from '@/components/atomic/molecules';
import { LINE_ITEM_SOURCE } from '@/config/common';
import { deleteCartItem, submitBookOrder } from '@/services/cart.plan.service';

const WIPCartModalsOrganism = ({
  cartItemIdForUploadMediaModal,
  cartItemIdInRequestToDeleteMode,
  commonProps,
  onClickDownloadQuotationPDF,
  setShowPDFDownloadConfirmationModal,
  showApplicableBookOrderModal,
  showPDFDownloadConfirmationModal
}) => {
  const Router = useRouter();
  const {
    cartDetails,
    getCartDetails,
    onClickCopyQuoteLink,
    setCartItemIdForUploadMediaModal,
    setCartItemIdInRequestToDeleteMode,
    setShowBookOrderModal,
    setShowToast,
    showBookOrderModal
  } = commonProps;

  const { id: cartId, userCartId: userId } = cartDetails;

  return (
    <>
      {cartItemIdInRequestToDeleteMode && (
        <ConfirmationModal
          {...{
            modalText: 'Are you sure you want to remove this item?',
            operation: () =>
              deleteCartItem({
                cartDetails: {
                  cartId,
                  cartItems: cartDetails.cartItems,
                  cartItemId: cartItemIdInRequestToDeleteMode
                },
                getCartDetails,
                setShowToast,
                userId
              }),
            setShowModal: () => setCartItemIdInRequestToDeleteMode(null)
          }}
        />
      )}
      {cartItemIdForUploadMediaModal && (
        <UploadCartItemMediaModal
          {...{
            cartId,
            cartItemId: cartItemIdForUploadMediaModal,
            productSource: LINE_ITEM_SOURCE.CART.value,
            setCartItemIdForUploadMediaModal,
            setShowToast
          }}
        />
      )}
      {showPDFDownloadConfirmationModal && (
        <PDFDownloadConfirmationModal
          {...{
            onClickCopyQuoteLink,
            onClickDownloadQuotationPDF,
            setShowPDFDownloadConfirmationModal,
            showPDFDownloadConfirmationModal
          }}
        />
      )}
      {showApplicableBookOrderModal && (
        <BookOrderModal
          {...{
            cartDetails,
            onSubmitBookOrder: (bookOrderPaymentDetails) =>
              submitBookOrder({
                bookOrderPaymentDetails,
                Router,
                cartId,
                userId
              }),
            setShowBookOrderModal,
            setShowToast,
            showBookOrderModal
          }}
        />
      )}
    </>
  );
};

export default WIPCartModalsOrganism;
