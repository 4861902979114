import { Highlight } from 'react-instantsearch';

import {
  CART_STATUS,
  HOST_INTERACTION_STATUS,
  NAVIGATION_ENTITY_TYPE,
  ORDER_STATUS,
  USER_EVENT_STAGE
} from 'config/common';
import { convertToStandardMobileNumberFormat } from 'lib/mobileNumberValidation';
import {
  convertToCurrencyFormat,
  parseFormatPriceValueFromAPI
} from 'lib/numberStringUtils';
import {
  convertToDateTimeString,
  convertToShortMonthDateFormat
} from 'lib/time';
import { parseNumberedLabel } from 'lib/utils';
import { getEditOrderURL } from 'services/opsPortal.service';
import { getPageURL, PAGE_NAME } from 'services/plannerPortal.service';

const getUserEventEntityInfoList = ({
  eventDate,
  eventTime = '',
  hostMobile,
  hostName,
  hostNumber,
  zendeskTicketNumber
}) => [
  {
    icon: 'zendesk-with-frame.svg',
    label: parseNumberedLabel(zendeskTicketNumber)
  },
  {
    icon: 'calendar-red-with-frame.svg',
    label: `${convertToDateTimeString({ date: eventDate, time: eventTime })}`
  },
  {
    icon: 'user-red-with-frame.svg',
    label: hostName
  },
  {
    icon: 'call-with-frame.svg',
    label: convertToStandardMobileNumberFormat(hostMobile) || 'NA'
  },
  {
    icon: 'number-icon.svg',
    label: parseNumberedLabel(hostNumber)
  }
];

const parseUserEventEntity = (hit) => {
  const {
    entityId,
    eventDate,
    eventTime,
    eventType,
    hostMobile,
    hostName,
    hostNumber,
    plannerName,
    stage,
    updatedAt,
    userEventNumber,
    zendeskTicketNumber
  } = hit;
  return {
    listCard: {
      entityId,
      icon: {
        alt: 'event',
        headerIcon: 'list-card-event.svg'
      },
      header: {
        subTitle: convertToStandardMobileNumberFormat(hostMobile) || 'NA',
        title: eventType
      },
      subHeader: {
        alt: 'zendesk ticket number',
        icon: 'zendesk-with-frame.svg',
        isBorder: true,
        value: (
          <Highlight
            {...{
              attribute: 'zendeskTicketNumber',
              hit
            }}
          />
        )
      },
      statusInfoAndAction: {
        entityNumber: {
          alt: 'user event number',
          icon: 'event-red-icon.svg',
          label: (
            <Highlight
              {...{
                attribute: 'userEventNumber',
                hit
              }}
            />
          )
        },
        entityStatus: {
          statusClass: USER_EVENT_STAGE[stage].class,
          value: USER_EVENT_STAGE[stage].label
        }
      }
    },
    previewCard: {
      cardHeader: {
        icon: {
          iconAlt: 'event',
          iconURL: 'navigate-preview-event.svg'
        },
        subTitle: {
          type: 'text',
          text: plannerName
        },
        title: eventType
      },
      entityId,
      entityInfoList: getUserEventEntityInfoList({
        eventDate,
        eventTime,
        hostMobile,
        hostName,
        hostNumber,
        zendeskTicketNumber
      }),
      lastUpdatedAt: `Last Updated ${convertToShortMonthDateFormat(updatedAt)}`,
      statusInfoAndAction: {
        entityNumber: {
          alt: 'user event number',
          icon: 'event-red-icon.svg',
          label: parseNumberedLabel(userEventNumber)
        },
        entityStatus: {
          statusClass: USER_EVENT_STAGE[stage].class,
          value: USER_EVENT_STAGE[stage].label
        }
      },
      viewCTA: {
        ctaLabel: 'Event',
        onClick: () => {
          window.open(
            getPageURL({
              pageName: PAGE_NAME.EVENT_CART_LIST.label,
              pathParams: { userEventId: entityId }
            }),
            '_blank'
          );
        }
      }
    }
  };
};

const getCartEntityInfoList = ({
  cartTotal,
  createdBy,
  hostMobile,
  hostName,
  hostNumber
}) => [
  {
    icon: 'planner-icon-orange.svg',
    label: createdBy
  },
  {
    icon: 'price-icon.svg',
    label: convertToCurrencyFormat({
      value: parseFormatPriceValueFromAPI(cartTotal)
    })
  },
  {
    icon: 'user-red-with-frame.svg',
    label: hostName
  },
  {
    icon: 'call-with-frame.svg',
    label: convertToStandardMobileNumberFormat(hostMobile) || 'NA'
  },
  {
    icon: 'number-icon.svg',
    label: parseNumberedLabel(hostNumber)
  }
];

const parseCartEntity = (hit) => {
  const {
    cartTotal,
    createdBy,
    entityId,
    entityName,
    entityNumber,
    hostMobile,
    hostName,
    hostNumber,
    status,
    updatedAt,
    userEventNumber
  } = hit;
  const { className: cartStatusClass, label: cartStatusLabel } =
    CART_STATUS[status.toUpperCase()];
  return {
    listCard: {
      entityId,
      icon: {
        alt: 'cart',
        headerIcon: 'list-card.svg'
      },
      header: {
        subTitle: convertToStandardMobileNumberFormat(hostMobile) || 'NA',
        title: (
          <Highlight
            {...{
              attribute: 'entityName',
              hit
            }}
          />
        )
      },
      subHeader: {
        alt: 'user event number',
        icon: 'event-red-icon.svg',
        isBorder: true,
        value: (
          <Highlight
            {...{
              attribute: 'userEventNumber',
              hit
            }}
          />
        )
      },
      statusInfoAndAction: {
        entityNumber: {
          alt: 'host number',
          icon: 'order-icon-with-frame-red.svg',
          label: (
            <Highlight
              {...{
                attribute: 'entityNumber',
                hit
              }}
            />
          )
        },
        entityStatus: {
          statusClass: `px-2 py-1 self-start rounded text-sm font-medium ${cartStatusClass}`,
          value: cartStatusLabel
        }
      }
    },
    previewCard: {
      cardHeader: {
        icon: {
          iconAlt: 'cart',
          iconURL: 'navigate-preview-cart.svg'
        },
        subTitle: {
          alt: 'user event number',
          icon: 'event-red-icon.svg',
          text: parseNumberedLabel(userEventNumber),
          type: 'textWithIcon'
        },
        title: entityName
      },
      entityId,
      entityInfoList: getCartEntityInfoList({
        cartTotal,
        createdBy,
        hostMobile,
        hostName,
        hostNumber
      }),
      lastUpdatedAt: `Last Updated ${convertToShortMonthDateFormat(updatedAt)}`,
      statusInfoAndAction: {
        entityNumber: {
          alt: 'host number',
          icon: 'order-icon-with-frame-red.svg',
          label: parseNumberedLabel(entityNumber)
        },
        entityStatus: {
          statusClass: `px-2 py-1 self-start rounded text-sm font-medium ${cartStatusClass}`,
          value: cartStatusLabel
        }
      },
      viewCTA: {
        ctaLabel: 'Cart',
        onClick: () => {
          const pageName =
            status === CART_STATUS.ORDER.value
              ? PAGE_NAME.ORDERED_CART.label
              : PAGE_NAME.PLANNER_CART.label;
          window.open(
            getPageURL({
              pageName,
              pathParams: { cartId: entityId }
            }),
            '_blank'
          );
        }
      }
    }
  };
};

const getOrderEntityInfoList = ({
  bookingDate,
  hostMobile,
  hostName,
  hostNumber,
  orderTotal
}) => [
  {
    icon: 'order-date.svg',
    label: convertToShortMonthDateFormat(bookingDate)
  },
  {
    icon: 'price-icon.svg',
    label: convertToCurrencyFormat({
      value: parseFormatPriceValueFromAPI(orderTotal)
    })
  },
  {
    icon: 'user-red-with-frame.svg',
    label: hostName
  },
  {
    icon: 'call-with-frame.svg',
    label: convertToStandardMobileNumberFormat(hostMobile) || 'NA'
  },
  {
    icon: 'number-icon.svg',
    label: parseNumberedLabel(hostNumber)
  }
];

const parseOrderEntity = (hit) => {
  const {
    bookingDate,
    entityId,
    entityName,
    entityNumber,
    hostMobile,
    hostName,
    hostNumber,
    orderTotal,
    status,
    updatedAt,
    userEventNumber
  } = hit;
  return {
    listCard: {
      entityId,
      icon: {
        alt: 'order',
        headerIcon: 'list-card-order.svg'
      },
      header: {
        subTitle: convertToStandardMobileNumberFormat(hostMobile) || 'NA',
        title: entityName
      },
      subHeader: {
        alt: 'user event number',
        icon: 'event-red-icon.svg',
        isBorder: true,
        value: (
          <Highlight
            {...{
              attribute: 'userEventNumber',
              hit
            }}
          />
        )
      },
      statusInfoAndAction: {
        entityNumber: {
          alt: 'order number',
          icon: 'product-icon-bold.svg',
          label: (
            <Highlight
              {...{
                attribute: 'entityNumber',
                hit
              }}
            />
          )
        },
        entityStatus: {
          statusClass: `px-2 py-1 self-start rounded text-sm font-medium ${ORDER_STATUS[status]?.class}`,
          value: ORDER_STATUS[status]?.label
        }
      }
    },
    previewCard: {
      cardHeader: {
        icon: {
          iconAlt: 'order',
          iconURL: 'navigate-preview-order.svg'
        },
        subTitle: {
          alt: 'user event number',
          icon: 'event-red-icon.svg',
          text: parseNumberedLabel(userEventNumber),
          type: 'textWithIcon'
        },
        title: entityName
      },
      entityId,
      entityInfoList: getOrderEntityInfoList({
        bookingDate,
        hostMobile,
        hostName,
        hostNumber,
        orderTotal
      }),
      lastUpdatedAt: `Last Updated ${convertToShortMonthDateFormat(updatedAt)}`,
      statusInfoAndAction: {
        entityNumber: {
          alt: 'order number',
          icon: 'product-icon-bold.svg',
          label: `# ${entityNumber}`
        },
        entityStatus: {
          statusClass: `px-2 py-1 self-start rounded text-sm font-medium ${ORDER_STATUS[status]?.class}`,
          value: ORDER_STATUS[status]?.label
        }
      },
      viewCTA: {
        ctaLabel: 'Order',
        onClick: () => {
          window.open(getEditOrderURL({ orderId: entityId }), '_blank');
        }
      }
    }
  };
};

const getHostEntityInfoList = ({ customerSegment, email, hostMobile }) => [
  {
    icon: 'call-with-frame.svg',
    label: convertToStandardMobileNumberFormat(hostMobile) || 'NA'
  },
  {
    icon: 'user-red-with-frame.svg',
    label: customerSegment || 'NA'
  },

  {
    icon: 'email-icon.svg',
    label: email
  }
];

const parseHostEntity = (hit) => {
  const {
    companyName,
    customerSegment,
    email,
    entityId,
    hostMobile,
    hostName,
    hostNumber,
    interactionStatus,
    updatedAt
  } = hit;
  return {
    listCard: {
      entityId,
      icon: {
        alt: 'host',
        headerIcon: 'list-card-host.svg'
      },
      header: {
        subTitle: convertToStandardMobileNumberFormat(hostMobile) || 'NA',
        title: (
          <Highlight
            {...{
              attribute: 'hostName',
              hit
            }}
          />
        )
      },
      subHeader: {
        alt: 'company name',
        icon: 'company-icon.svg',
        isBorder: false,
        value: companyName && (
          <Highlight
            {...{
              attribute: 'companyName',
              hit
            }}
          />
        )
      },
      statusInfoAndAction: {
        entityNumber: {
          alt: 'host number',
          icon: 'number-icon.svg',
          label: (
            <Highlight
              {...{
                attribute: 'hostNumber',
                hit
              }}
            />
          )
        },
        entityStatus: {
          statusClass: `${HOST_INTERACTION_STATUS[interactionStatus].className} px-2 py-1 self-start rounded text-sm font-medium`,
          value: HOST_INTERACTION_STATUS[interactionStatus].label
        }
      }
    },
    previewCard: {
      cardHeader: {
        icon: {
          iconAlt: 'host',
          iconURL: 'navigate-preview-host.svg'
        },
        subTitle: {
          type: 'text',
          text: companyName
        },
        title: hostName
      },
      entityId,
      entityInfoList: getHostEntityInfoList({
        customerSegment,
        email,
        hostMobile
      }),
      lastUpdatedAt: `Last Updated ${convertToShortMonthDateFormat(updatedAt)}`,
      statusInfoAndAction: {
        entityNumber: {
          alt: 'host number',
          icon: 'number-icon.svg',
          label: parseNumberedLabel(hostNumber)
        },
        entityStatus: {
          statusClass: `${HOST_INTERACTION_STATUS[interactionStatus].className} px-2 py-1 self-start rounded text-sm font-medium`,
          value: HOST_INTERACTION_STATUS[interactionStatus].label
        }
      },
      viewCTA: {
        ctaLabel: 'Profile',
        onClick: () => {
          window.open(
            getPageURL({
              pageName: PAGE_NAME.HOST_EVENT_LIST.label,
              pathParams: { userId: entityId }
            }),
            '_blank'
          );
        }
      }
    }
  };
};

export const transformSearchResultList = ({ searchResults }) => {
  const transformFunctionMap = {
    [NAVIGATION_ENTITY_TYPE.CART]: parseCartEntity,
    [NAVIGATION_ENTITY_TYPE.HOST]: parseHostEntity,
    [NAVIGATION_ENTITY_TYPE.ORDER]: parseOrderEntity,
    [NAVIGATION_ENTITY_TYPE.USER_EVENT]: parseUserEventEntity
  };

  return searchResults.map(({ entityType, ...rest }) =>
    transformFunctionMap[entityType]({ ...rest, entityType })
  );
};

export default transformSearchResultList;
