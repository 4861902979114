import Image from 'next/image';
import Link from 'next/link';

import { CartItemCTA, CartItemSummary } from '@/components/atomic/atoms';
import { LineItemMedia } from '@/components/atomic/molecules';
import { Button, Text } from '@/components/atomic/nuclei';
import {
  UNAVAILABLE_CART_ITEM_ALERT,
  staticMediaStoreBaseURL
} from '@/config/common';
import { getProductDetailPageURL } from '@/services/hostPortal.service';

const UnavailableCartItemList = ({ isCartEditable, cartItem }) => {
  const { id, itemMediaForCarousel, product, showProductMedia } = cartItem;
  const { name, slug } = product;

  return (
    <div className='w-full'>
      <div
        className={`flex gap-5 text-sm font-light py-3 ${
          isCartEditable ? 'border-b' : ''
        } px-4`}
      >
        <div className='flex-1 flex'>
          <LineItemMedia
            {...{
              isCartEditable,
              itemId: id,
              itemMediaForCarousel,
              productName: name,
              showProductMedia,
              unAvailableCartItem: true
            }}
          />
          <Link
            className='self-center'
            href={getProductDetailPageURL({ slug })}
          >
            <Text
              {...{
                className: 'flex-1 flex text-red-600 ml-5',
                content: name
              }}
            />
          </Link>
        </div>
        <CartItemSummary {...{ cartItem, isUnAvailableCartItem: true }} />
        <div className='w-40 self-center flex gap-3'>
          <Button
            {...{
              className: `opacity-40 pointer-events-none bg-gradient-to-r h-8 from-light-orange via-dark-orange to-rose text-white px-2 py-2 rounded-lg text-sm font-medium`,
              iconGroupClassName: 'gap-0',
              iconHeight: 15,
              iconPosition: 'Left',
              iconURL: `${staticMediaStoreBaseURL}/icons/view-icon.svg`,
              iconWidth: 15,
              label: 'View',
              width: 'w-20'
            }}
          />
          <CartItemCTA
            {...{
              className: 'self-center opacity-40 pointer-events-none',
              type: 'delete'
            }}
          />
        </div>
      </div>
    </div>
  );
};
const UnavailableCartItemListSectionHeader = () => (
  <div className='bg-unavailable-error py-3 px-4 flex gap-2 mx-4'>
    <Image
      {...{
        alt: 'Unavailable CartItems',
        height: 0,
        src: `${staticMediaStoreBaseURL}/icons/failed.svg`,
        style: { height: 16, width: 16 },
        width: 0
      }}
    />
    <Text
      {...{
        className: 'text-xs font-medium text-error-red flex-1 self-center',
        content: UNAVAILABLE_CART_ITEM_ALERT
      }}
    />
  </div>
);

const UnavailableCartItemListSectionFooter = ({ onClick }) => (
  <div className='flex justify-end float-right mt-2 mr-4'>
    <Button
      {...{
        className:
          'bg-gradient-to-r from-light-orange via-dark-orange to-rose text-white px-3 py-2 rounded-lg text-sm font-medium',
        label: 'Remove All',
        onClick,
        width: 'w-32'
      }}
    />
  </div>
);

const UnavailableWIPCartItemOrganism = ({
  itemList = [],
  itemListComponentCommonProps,
  onClickRemoveUnavailableCartItem
}) => {
  const {
    isCartEditable = true,
    setCartItemIdForUploadMediaModal,
    updateCartItemMediaSortOrder
  } = itemListComponentCommonProps;

  return (
    <div className='border rounded-lg pb-3 pt-4'>
      <UnavailableCartItemListSectionHeader />
      {itemList.map((item, index) => (
        <UnavailableCartItemList
          key={`${item.id}-${index}`}
          {...{
            cartItem: item,
            isCartEditable,
            setCartItemIdForUploadMediaModal,
            updateCartItemMediaSortOrder
          }}
        />
      ))}
      {isCartEditable && (
        <UnavailableCartItemListSectionFooter
          {...{
            onClick: () =>
              itemList.map(({ id: cartItemId }) =>
                onClickRemoveUnavailableCartItem({ cartItemId })
              )
          }}
        />
      )}
    </div>
  );
};

export default UnavailableWIPCartItemOrganism;
