import Image from 'next/image';
import Link from 'next/link';

import { EditableCartSummaryModal } from '@/components/atomic/molecules';
import { Button, Text, TextWithIcon } from '@/components/atomic/nuclei';
import { CART_STATUS, staticMediaStoreBaseURL } from '@/config/common';
import { PAGE_NAME, getPageURL } from '@/services/plannerPortal.service';

const ViewEventCTA = ({ eventId }) => (
  <div
    className='flex text-white border border-white py-0 rounded-lg text-sm font-medium h-8 items-center'
    onClick={(e) => e.stopPropagation()}
  >
    <Link
      href={getPageURL({
        pageName: PAGE_NAME.EVENT_CART_LIST.label,
        pathParams: { userEventId: eventId }
      })}
    >
      <div className='flex gap-1 items-center px-2 cursor-pointer'>
        <Image
          {...{
            alt: 'view event',
            height: 0,
            src: `${staticMediaStoreBaseURL}/icons/view-white-icon.svg`,
            style: { height: 16, width: 16 },
            width: 0
          }}
        />
        <Text {...{ className: 'self-center pl-1', content: 'Events' }} />
      </div>
    </Link>
  </div>
);

const ViewUserEventCTA = ({
  cartName,
  cartNumber,
  createdAt,
  deliveryDate,
  eventId,
  id,
  isDefault,
  isReadOnly,
  pickupDate,
  plannerUserName,
  setEditCartSummaryModal,
  status,
  updatedAt
}) => (
  <div className='flex items-center gap-4 justify-between mt-4 w-full relative'>
    {!isDefault && (
      <>
        <div className='flex items-center gap-4'>
          <ViewEventCTA {...{ eventId }} />
          {!isReadOnly && (
            <Button
              {...{
                className:
                  'bg-white text-brand px-1 py-[6px] rounded-lg text-sm font-medium',
                iconGroupClassName: 'gap-0 !space-x-1',
                iconHeight: 18,
                iconPosition: 'Left',
                iconURL: `${staticMediaStoreBaseURL}/icons/edit-red-with-frame.svg`,
                iconWidth: 18,
                label: 'Cart',
                onClick: (e) => {
                  e.stopPropagation();
                  setEditCartSummaryModal({
                    showModal: true,
                    cartSummary: {
                      cartName,
                      cartNumber,
                      createdAt,
                      deliveryDate,
                      id,
                      pickupDate,
                      status,
                      updatedAt
                    }
                  });
                },
                width: 'w-24'
              }}
            />
          )}
        </div>
        <TextWithIcon
          {...{
            className: 'shadow-none',
            icon: 'planner-icon.svg',
            iconHeight: 28,
            iconWidth: 28,
            label: plannerUserName,
            labelStyle: 'text-base font-medium text-white',
            onClick: (e) => e.stopPropagation()
          }}
        />
      </>
    )}
  </div>
);

const HeaderCartDetails = ({ cartName, cartNumber, status }) => {
  const stopEventPropagation = (e) => e.stopPropagation();
  return (
    <div className='flex flex-col gap-3 w-full'>
      <div className='flex justify-between items-center'>
        <Text
          {...{
            className: 'truncate font-semibold text-xl',
            content: cartName,
            onClick: stopEventPropagation
          }}
        />
        <div className='flex gap-4 items-center'>
          <Text
            {...{
              className: `px-2 py-1 text-base rounded ${CART_STATUS[status.toUpperCase()].className}`,
              content: status,
              onClick: stopEventPropagation
            }}
          />
          <TextWithIcon
            {...{
              className: 'shadow-none px-2 rounded bg-white self-center',
              icon: 'order-icon-with-frame-red.svg',
              iconHeight: 24,
              iconWidth: 24,
              label: `# ${cartNumber}`,
              labelStyle:
                'text-base font-semibold text-dim-gray self-center flex py-1',
              onClick: stopEventPropagation
            }}
          />
        </div>
      </div>
    </div>
  );
};

const CartInfoSummary = ({
  cartName,
  cartNumber,
  createdAt,
  deliveryDate,
  eventId,
  id,
  isDefault,
  isReadOnly,
  orderId,
  orderNumber,
  pickupDate,
  plannerUserName,
  setEditCartSummaryModal,
  setShowCartEventInfo,
  showCartEventInfo,
  status,
  updatedAt
}) => {
  const deliveryDateToShow = deliveryDate || '';
  const pickupDateToShow = pickupDate || '';
  return (
    <div
      className='flex flex-wrap justify-between cursor-pointer'
      onClick={() => setShowCartEventInfo(!showCartEventInfo)}
    >
      <HeaderCartDetails
        {...{
          cartName,
          cartNumber,
          deliveryDateToShow,
          orderId,
          orderNumber,
          pickupDateToShow,
          status
        }}
      />
      <ViewUserEventCTA
        {...{
          cartName,
          cartNumber,
          createdAt,
          deliveryDate,
          eventId,
          id,
          isDefault,
          isReadOnly,
          pickupDate,
          plannerUserName,
          setEditCartSummaryModal,
          showCartEventInfo,
          status,
          updatedAt
        }}
      />
      {!isDefault && (
        <div className='absolute -bottom-5 right-4'>
          <Image
            {...{
              alt: 'show cart info',
              className: `cursor-pointer transform ${
                showCartEventInfo ? 'rotate-180' : 'rotate-0'
              }`,
              height: 0,
              src: `${staticMediaStoreBaseURL}/icons/down-angle-circle-icon.svg`,
              style: { hight: 40, width: 40 },
              width: 0
            }}
          />
        </div>
      )}
    </div>
  );
};

const CartInfoSummaryOrganism = ({
  cartDetails,
  editCartSummaryModal,
  orderId,
  orderNumber,
  setEditCartSummaryModal,
  setLoading,
  setShowCartEventInfo,
  setShowToast,
  setUpdateCartDetail,
  showCartEventInfo
}) => {
  const {
    cartName,
    cartNumber,
    createdAt,
    deliveryDate,
    id,
    isDefault,
    isReadOnly,
    pickupDate,
    status,
    updatedAt,
    userEventSummary
  } = cartDetails;

  if (!id) return '';
  const isOrderedCard = status === CART_STATUS.ORDER.value;

  const { userEventDetails } = userEventSummary || {};
  const [{ eventDate = '', opsUser, userEventId }] = userEventDetails || [{}];
  const plannerUserName = opsUser?.name || 'NA';
  const shouldDisplayEditModalForNonDefaultCart =
    !isDefault && editCartSummaryModal?.showModal;

  const eventId = isOrderedCard ? userEventId : userEventSummary?.id;

  return (
    <div className='p-5'>
      {shouldDisplayEditModalForNonDefaultCart && (
        <EditableCartSummaryModal
          {...{
            editCartSummaryModal,
            setEditCartSummaryModal,
            setLoading,
            setShowToast,
            setShowUserEventProfile: setShowCartEventInfo,
            setUserEventCartDetails: setUpdateCartDetail,
            userEventInformation: userEventSummary
          }}
        />
      )}
      <CartInfoSummary
        {...{
          cartName,
          cartNumber,
          createdAt,
          deliveryDate,
          eventDate,
          eventId,
          id,
          isDefault,
          isReadOnly,
          orderId,
          orderNumber,
          pickupDate,
          plannerUserName,
          setEditCartSummaryModal,
          setShowCartEventInfo,
          showCartEventInfo,
          status,
          updatedAt
        }}
      />
    </div>
  );
};

export default CartInfoSummaryOrganism;
