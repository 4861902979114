import Image from 'next/image';
import { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, ModalBody } from 'reactstrap';
import * as yup from 'yup';

import { Radio } from '@/components/atomic/atoms';
import {
  Button,
  CloseIcon,
  InputField,
  Text,
  TextWithIcon
} from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

import { captureGTMEventProceedToPayment } from '@/lib/gtm';
import {
  formatToTwoDecimalString,
  parseFormatPriceValueFromAPI,
  parsePriceValueForAPI
} from '@/lib/numberStringUtils';
import { parseNumberedLabel } from '@/lib/utils';
import useYupValidator from '@/lib/yupValidator';
import {
  amountFields,
  modeOfConfirmations,
  otherFields,
  paymentModes
} from '@/services/cart.plan.service';

const BookOrderPaymentModalHeader = ({ modalHeaderTextWithIconList }) => (
  <div className='flex justify-between pt-3 pb-5 text-base font-medium'>
    {modalHeaderTextWithIconList.map(({ icon, label }) => (
      <TextWithIcon
        key={label}
        {...{
          className: 'py-1 px-2 border border-neutral rounded',
          icon,
          iconHeight: 20,
          iconWidth: 20,
          label: parseNumberedLabel(label),
          labelStyle: 'text-sm text-dim-gray'
        }}
      />
    ))}
  </div>
);

const InfoMessage = ({ message, top, width }) =>
  message && (
    <div className='relative flex'>
      <div className='book-order-info cursor-pointer'>
        <Image
          alt='info'
          height={0}
          src={`${staticMediaStoreBaseURL}/icons/info-icon.svg`}
          style={{ height: 16, width: 16 }}
          width={0}
        />

        <Text
          className={`text-xs book-order-info-message font-light text-nero bg-error-tool-tip absolute ${width} p-3 rounded-xl z-10 left-10 ${top} tooltip`}
          content={message}
          HtmlTag='span'
        />
      </div>
    </div>
  );

const SectionHeading = ({
  className,
  label,
  message,
  top = '-top-3',
  width = 'w-100'
}) => (
  <div className='flex gap-1 relative'>
    <Text
      {...{
        className: `text-base font-medium self-center ${className}`,
        content: label
      }}
    />
    <InfoMessage {...{ top, width, message }} />
  </div>
);

const ModeOfConfirmation = ({
  errors,
  getValues,
  handleModeOfCommunication,
  register
}) => (
  <Fragment>
    <SectionHeading
      {...{
        label: 'Mode of confirmation',
        message: `Mark Prepaid, If the customer has made any advance payment to.wards the order. Otherwise mark the appropriate channel where the customer agreed to pay`,
        top: '-top-6'
      }}
    />
    <div className='my-4'>
      <div className='flex gap-4'>
        {Object.values(modeOfConfirmations).map(({ label, value }, index) => (
          <div
            className={`w-42.5 bg-lightgray h-11 rounded p-3 text-black shadow-card ${
              getValues('modeOfConfirmation') === value
                ? 'border border-brand'
                : ''
            }`}
            key={index}
            onClick={() => handleModeOfCommunication(value)}
          >
            <Radio
              {...{
                dbName: 'modeOfConfirmation',
                direction: 'right',
                errors,
                label,
                labelClass: 'text-sm font-medium',
                name: 'modeOfConfirmation',
                radioClass: 'radio-w16',
                register,
                value
              }}
            />
          </div>
        ))}
      </div>
      <div className='text-xs text-red-500 mt-1'>
        {errors?.modeOfConfirmation?.message}
      </div>
    </div>
  </Fragment>
);

const ModeOfPayment = ({
  errors,
  getValues,
  handleModeOfPayment,
  register
}) => (
  <Fragment>
    <SectionHeading
      {...{
        className: 'mt-1',
        label: 'Mode of payment',
        message: 'Mandatory if Mode of confirmation is Prepaid.'
      }}
    />
    <div className='my-4'>
      <div className='flex gap-4'>
        {Object.values(paymentModes).map(({ icon, label, value }, index) => (
          <div
            key={index}
            className={`w-40 bg-lightgray text-black p-3 h-18 rounded shadow-card flex flex-col gap-2 ${
              getValues('paymentProviderType') === value
                ? 'border border-brand'
                : ''
            }`}
            onClick={() => handleModeOfPayment(value)}
          >
            <div className='flex justify-between'>
              <Image
                alt='payment'
                height={0}
                src={`${staticMediaStoreBaseURL}/icons/${icon}`}
                style={{ height: 28, width: 28 }}
                width={0}
              />
              <Radio
                {...{
                  dbName: 'paymentProviderType',
                  direction: 'right',
                  errors,
                  labelClass: 'text-sm font-medium',
                  name: 'paymentProviderType',
                  radioClass: 'radio-w16',
                  register,
                  value
                }}
              />
            </div>
            <Text
              {...{
                className: 'text-sm font-medium',
                content: label
              }}
            />
          </div>
        ))}
      </div>
      <div className='text-xs text-red-500 mt-1'>
        {errors?.paymentProviderType?.message}
      </div>
    </div>
  </Fragment>
);

const PaymentInfoRow = ({ handleAmountReceived, errors, register, watch }) => (
  <div className='flex gap-4'>
    {amountFields.map(
      ({ dbName, disable, inputType, label, placeholder }, key) => (
        <div
          className='w-1/3'
          key={key}
        >
          <SectionHeading
            {...{
              label,
              message:
                dbName === 'amountReceived' &&
                'Enter a positive real number without AED.'
            }}
          />
          <InputField
            {...{
              className: 'rounded-lg text-base',
              dbName,
              disabled:
                disable ||
                (watch &&
                  watch('modeOfConfirmation') !==
                    modeOfConfirmations.PREPAID.value),
              errors,
              inputGroup: 'w-full mt-3',
              onChange: handleAmountReceived,
              placeholder,
              register: register(dbName),
              type: inputType
            }}
          />
        </div>
      )
    )}
  </div>
);

const PaymentRemarkRow = ({ errors, register }) => (
  <div className='flex gap-4 my-5'>
    {otherFields.map(
      ({ infoMessage, inputType, placeholder, dbName, label }, key) => (
        <div
          className={`flex flex-col ${
            dbName === 'reference' ? 'w-1/3' : 'w-2/3'
          }`}
          key={key}
        >
          <SectionHeading
            {...{
              errors,
              label,
              message: infoMessage,
              top: dbName === 'paymentRemarks' ? '-top-8' : '-top-3',
              width: dbName === 'paymentRemarks' ? 'w-56' : 'w-100'
            }}
          />
          <InputField
            {...{
              className: 'rounded-lg text-base',
              dbName,
              errors,
              inputGroup: 'w-full mt-3',
              placeholder,
              register: register(dbName),
              type: inputType
            }}
          />
        </div>
      )
    )}
  </div>
);

const BookOrderModal = ({
  cartDetails,
  onSubmitBookOrder,
  setShowBookOrderModal,
  setShowToast,
  showBookOrderModal
}) => {
  const {
    cartItems,
    cartNumber,
    derivedValues: {
      orderTotal: orderTotalValue,
      promoCode: { code } = {}
    } = {},
    userCart: { hostNumber }
  } = cartDetails;

  const yupResolver = useYupValidator(
    yup.object().shape({
      modeOfConfirmation: yup
        .string()
        .trim()
        .typeError('Mode Of Confirmation is mandatory!')
        .required('Mode Of Confirmation is mandatory!'),
      paymentProviderType: yup
        .string()
        .trim()
        .when('modeOfConfirmation', {
          is: modeOfConfirmations.PREPAID.value,
          then: () =>
            yup
              .string()
              .trim()
              .typeError(
                'Mode of payment is mandatory when prepaid confirmation!'
              )
              .required(
                'Mode of payment is mandatory when prepaid confirmation!'
              )
        })
        .nullable(),
      amountReceived: yup.number().when('modeOfConfirmation', {
        is: modeOfConfirmations.PREPAID.value,
        then: () =>
          yup.number().positive('Amount received should be > 0 when prepaid')
      }),
      paymentProviderId: yup
        .string()
        .trim()
        .required('Reference # is mandatory!'),
      paymentRemarks: yup
        .string()
        .trim()
        .required('Payment remarks is mandatory!')
    })
  );

  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setValue,
    watch
  } = useForm({
    resolver: yupResolver,
    defaultValues: {
      amountDue: parseFormatPriceValueFromAPI(orderTotalValue) || '0.00',
      amountReceived: '0.00',
      orderTotal: parseFormatPriceValueFromAPI(orderTotalValue) || '0.00'
    }
  });

  const getAmountDue = (amountReceived) =>
    formatToTwoDecimalString({
      value: getValues('orderTotal') - amountReceived,
      convertValueToUpperDenomination: false,
      trimZeroFraction: false
    });

  const handleAmountReceived = (data) => {
    setValue('amountDue', getAmountDue(data.target.value || 0));
  };

  const handleModeOfCommunication = (value) => {
    setValue('modeOfConfirmation', value);
    setValue('amountReceived', 0);
    setValue('amountDue', getValues('orderTotal') || 0);
  };

  const handleModeOfPayment = (value) => {
    setValue('paymentProviderType', value, { shouldValidate: true });
  };

  const onSubmit = async ({
    amountDue: paymentDue,
    amountReceived: transactionAmount,
    modeOfConfirmation,
    orderTotal,
    paymentProviderId,
    paymentProviderType,
    paymentRemarks
  }) => {
    const { status, message } = await onSubmitBookOrder({
      modeOfConfirmation,
      orderTotal: parsePriceValueForAPI(orderTotal),
      paymentDue: parsePriceValueForAPI(paymentDue),
      paymentProviderId,
      paymentProviderType,
      paymentRemarks,
      transactionAmount: parsePriceValueForAPI(transactionAmount)
    });
    captureGTMEventProceedToPayment({
      cartItems,
      code,
      orderTotal,
      paymentType: paymentProviderType
    });
    if (!status) {
      setShowToast({ show: true, message, status });
    }
  };

  const toggleBookOrderModalView = () =>
    setShowBookOrderModal(!showBookOrderModal);

  const modalHeaderTextWithIconList = [
    {
      icon: 'order-red-icon.svg',
      label: cartNumber
    },
    {
      icon: 'number-icon.svg',
      label: hostNumber
    }
  ];

  return (
    <Modal
      isOpen={true}
      toggle={toggleBookOrderModalView}
    >
      <ModalBody
        className='z-120 bg-white m-18 w-full md:w-56.25
      min-h-96 mx-auto px-8 md:rounded-lg pb-2 pt-4'
      >
        <div className='flex-1 flex flex-row-reverse relative left-4'>
          <CloseIcon {...{ onClick: toggleBookOrderModalView }} />
        </div>
        <Text
          {...{
            className: 'text-center text-xl font-medium pb-5',
            content: 'Book Order - Payment Details'
          }}
        />
        <BookOrderPaymentModalHeader
          {...{
            modalHeaderTextWithIconList
          }}
        />
        <div className='flex flex-col text-xl'>
          <ModeOfConfirmation
            {...{
              errors,
              getValues,
              handleModeOfCommunication,
              register
            }}
          />
          <ModeOfPayment
            {...{
              errors,
              getValues,
              handleModeOfPayment,
              register
            }}
          />
          <PaymentInfoRow
            {...{
              errors,
              handleAmountReceived,
              register,
              watch
            }}
          />
          <PaymentRemarkRow {...{ register, errors }} />
        </div>
        <Button
          {...{
            className: `py-3 px-2 text-sm font-medium mx-auto my-4 rounded-lg bg-brand-gradient text-white`,
            label: 'Confirm Order',
            onClick: handleSubmit(onSubmit),
            width: 'w-40'
          }}
        />
      </ModalBody>
    </Modal>
  );
};

export default BookOrderModal;
