import { useState } from 'react';
import {
  RefinementList,
  ToggleRefinement,
  useCurrentRefinements
} from 'react-instantsearch';

import { RangeSliderRefinement } from '@/components/atomic/atoms';
import { DynamicFilter } from '@/components/atomic/molecules';
import { Button } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { DEFAULT_FILTER_OPTIONS_LIMIT } from '@/config/searchKit';

const classNameGroup = {
  checkbox:
    'content-searchkit-checkbox searchkit-checkbox checked:content-searchkit-checkbox-checked',
  count: 'bg-brand text-white border-none font-medium text-xs',
  labelText:
    'text-sm text-dim-gray font-medium searchkit-label-text cursor-pointer',
  list: 'flex flex-col gap-4',
  searchBox: 'searchkit-search-box',
  searchableForm: 'searchkit-search-box-form',
  selectedItem: 'searchkit-selected-item',
  showMore: 'searchkit-filter-show-more-button'
};

const getCountForAttribute = ({ attribute, currentRefinements }) => {
  const { refinements: attributeRefinements = [] } =
    currentRefinements.find(
      (refinement) => refinement.attribute === attribute
    ) || {};
  return attributeRefinements.length;
};

const hideShowMoreLessCTA = true;

const ProductSearchFiltersOrganism = () => {
  const [openFilter, setOpenFilter] = useState(null);
  const [viewAllFilters, setViewAllFilters] = useState(false);

  const { items: currentRefinements } = useCurrentRefinements();

  const handleToggleFilter = (attribute) => {
    setOpenFilter((prevOpenFilter) =>
      prevOpenFilter === attribute ? null : attribute
    );
  };

  const dynamicFilterList = [
    {
      attribute: 'productPartners.partner.legalName',
      buttonName: 'Partners',
      Component: RefinementList,
      componentProps: {
        attribute: 'productPartners.partner.legalName',
        classNames: {
          ...classNameGroup
        },
        limit: DEFAULT_FILTER_OPTIONS_LIMIT,
        searchable: true,
        searchablePlaceholder: 'Search',
        showMore: true,
        sortBy: ['isRefined', 'name:asc']
      },
      count: getCountForAttribute({
        attribute: 'productPartners.partner.legalName',
        currentRefinements
      }),
      icon: 'partner-icon.svg',
      isOpenFilterDrawer: openFilter === 'productPartners.partner.legalName',
      onToggleAttribute: handleToggleFilter,
      selectedIcon: 'partner-icon-white.svg'
    },
    {
      attribute: 'price.sortPrice',
      buttonName: 'Price Range',
      Component: RangeSliderRefinement,
      componentProps: {
        attribute: 'price.sortPrice',
        title: 'Price Range',
        headerPrefix: 'AED'
      },
      count: getCountForAttribute({
        attribute: 'price.sortPrice',
        currentRefinements
      }),
      icon: 'home-price-icon.svg',
      isOpenFilterDrawer: openFilter === 'price.sortPrice',
      onToggleAttribute: handleToggleFilter,
      selectedIcon: 'price-icon-white.svg'
    },
    {
      attribute: 'coreVertical',
      buttonName: 'Core Verticals',
      Component: RefinementList,
      componentProps: {
        attribute: 'coreVertical',
        classNames: {
          ...classNameGroup,
          list: `${classNameGroup.list} mt-4`,
          root: 'searchkit-search-box-event-type'
        },
        limit: DEFAULT_FILTER_OPTIONS_LIMIT,
        showMore: true,
        sortBy: ['name:asc']
      },
      count: getCountForAttribute({
        attribute: 'coreVertical',
        currentRefinements
      }),
      icon: 'category-icon.svg',
      isOpenFilterDrawer: openFilter === 'coreVertical',
      onToggleAttribute: handleToggleFilter,
      selectedIcon: 'category-icon-white.svg'
    },
    {
      attribute: 'type',
      buttonName: 'Product Type',
      Component: RefinementList,
      componentProps: {
        attribute: 'type',
        classNames: {
          ...classNameGroup
        }
      },
      count: getCountForAttribute({
        attribute: 'type',
        currentRefinements
      }),
      hideFilter: true,
      icon: 'product-icon.svg',
      isOpenFilterDrawer: openFilter === 'type',
      onToggleAttribute: handleToggleFilter,
      selectedIcon: 'product-icon-white.svg'
    },
    {
      attribute: 'status',
      buttonName: 'Status',
      Component: RefinementList,
      componentProps: {
        attribute: 'status',
        classNames: {
          ...classNameGroup
        },
        limit: DEFAULT_FILTER_OPTIONS_LIMIT,
        searchablePlaceholder: 'Search',
        showMore: true
      },
      count: getCountForAttribute({
        attribute: 'status',
        currentRefinements
      }),
      hideFilter: true,
      icon: 'status-icon.svg',
      isOpenFilterDrawer: openFilter === 'status',
      onToggleAttribute: handleToggleFilter,
      selectedIcon: 'status-icon-white.svg'
    },
    {
      attribute: 'isDiscountAvailable',
      buttonName: 'Discount',
      Component: ToggleRefinement,
      componentProps: {
        attribute: 'isDiscountAvailable',
        label: 'Discounted Products Only',
        classNames: {
          checkbox: 'checked:!bg-brand !shadow-none !bg-platinum',
          labelText: 'text-sm text-dim-gray font-medium'
        }
      },
      count: getCountForAttribute({
        attribute: 'isDiscountAvailable',
        currentRefinements
      }),
      hideFilter: true,
      icon: 'discount-icon.svg',
      isOpenFilterDrawer: openFilter === 'isDiscountAvailable',
      onToggleAttribute: handleToggleFilter,
      selectedIcon: 'discount-icon-white.svg'
    }
  ];

  return (
    <div className='flex relative justify-center w-50 mx-auto'>
      <div className='searchkit-filter-container home-page-filter flex justify-center flex-wrap gap-x-3 gap-y-3'>
        {dynamicFilterList.map((props, index) => (
          <DynamicFilter
            key={index}
            {...props}
          />
        ))}
        {!hideShowMoreLessCTA && (
          <Button
            {...{
              className:
                'text-brand text-sm font-medium border border-brand px-3 py-1 rounded-md shadow-sm',
              iconHeight: 20,
              iconPosition: 'Left',
              iconURL: `${staticMediaStoreBaseURL}/icons/more.svg`,
              iconWidth: 20,
              label: viewAllFilters ? 'Less' : 'More',
              onClick: () => setViewAllFilters(!viewAllFilters),
              width: 'w-24'
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ProductSearchFiltersOrganism;
