import Image from 'next/image';
import { useRouter } from 'next/router';
import { Fragment, useState } from 'react';
import { Tooltip } from 'reactstrap';

import { EPICBrief } from '@/components/atomic/atoms';
import {
  Text,
  TextKeyValuePair,
  TextWithIcon
} from '@/components/atomic/nuclei';
import {
  ALLOCATION_STATUS,
  mediaStoreBaseURL,
  staticMediaStoreBaseURL,
  USER_EVENT_STAGE
} from '@/config/common';
import { handleOnClickZendesk } from '@/helpers/zendeskTicket';
import { useIsMobile } from '@/lib/screenResolution';
import {
  convertToShortMonthDateFormat,
  formatDateToHaflaStandardFormat,
  formatTimeToAmPm
} from '@/lib/time';
import { parseNumberedLabel } from '@/lib/utils';
import {
  checkIsEmptyOrDummyEventDetail,
  getLabelForEventRequestIsEndToEnd
} from '@/services/eventCartDetail.service';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const SideBarHead = ({ displayUserEventProfile, isMobile, userEvent }) => {
  const {
    stage,
    userEventDetails: [{ checkoutEvent = {} } = {}] = [],
    userEventNumber,
    zendeskTicketDetail
  } = userEvent;
  const { eventRequestIsEndToEnd } = zendeskTicketDetail || {};
  return (
    <div className='flex flex-row md:flex-col gap-4'>
      <div className='flex items-center gap-4'>
        <div className={`${isMobile ? 'w-14' : 'w-24'} rounded-lg`}>
          <Image
            alt='Event'
            className='rounded-lg'
            height={0}
            id='user-event-icon'
            priority={true}
            src={`${mediaStoreBaseURL}/${checkoutEvent.iconUrl}`}
            style={{
              height: isMobile ? 60 : 100,
              width: isMobile ? 60 : 100
            }}
            unoptimized={true}
            width={0}
          />
        </div>
        <TextKeyValuePair
          {...{
            className: 'flex-col !gap-2',
            label: checkoutEvent?.event.name,
            labelClass: 'text-brand text-xl w-72 truncate-text font-semibold',
            spaceTop: 'mt-0',
            value: getLabelForEventRequestIsEndToEnd(eventRequestIsEndToEnd),
            valueClassName: 'text-base font-medium text-nero'
          }}
        />
      </div>
      <div className='flex flex-col'>
        <div className='flex justify-between mb-2 mt-1'>
          <div
            className='flex border border-brand rounded-lg px-2 py-1 items-center gap-4 md:gap-2'
            onClick={displayUserEventProfile}
          >
            <Image
              alt='view'
              className='w-auto h-auto'
              height={0}
              src={`${staticMediaStoreBaseURL}/icons/view.svg`}
              style={{ height: 15, width: 15 }}
              width={0}
            />
            <Text
              className='text-brand text-sm font-medium cursor-pointer self-center leading-4'
              content='Event'
            />
          </div>
          <div className='flex gap-2'>
            {stage && (
              <Text
                {...{
                  className: `${USER_EVENT_STAGE[stage].class} rounded-lg px-2 py-1 text-sm font-medium self-center items-center flex`,
                  content: USER_EVENT_STAGE[stage].label
                }}
              />
            )}
            <TextWithIcon
              {...{
                className:
                  'bg-white border-neutral border rounded-lg px-3 py-1 self-center items-center flex',
                icon: 'event-red-icon.svg',
                label: parseNumberedLabel(userEventNumber),
                labelStyle: 'text-sm font-medium text-dim-gray'
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const PlannerAndAllocationStatusInfo = ({ allocationStatus, opsUser }) => (
  <div className='flex gap-4 py-1'>
    <Image
      alt='planner'
      height={0}
      src={`${staticMediaStoreBaseURL}/icons/planner-icon-orange.svg`}
      style={{ height: 48, width: 48 }}
      width={0}
    />
    <TextKeyValuePair
      {...{
        label: opsUser?.name || 'NA',
        labelClass: 'text-lg font-semibold text-dim-gray',
        value: ALLOCATION_STATUS[allocationStatus]?.label || 'NA',
        valueClassName: 'text-sm font-medium text-dim-gray',
        className: 'flex-col !gap-0'
      }}
    />
  </div>
);

const SideBarBody = ({ router, userEvent }) => {
  const {
    user: { id: userId },
    userEventDetails,
    zendeskTicketId
  } = userEvent;
  const [
    {
      allocationStatus,
      eventContactName,
      eventDate,
      eventStartTime,
      eventAddress,
      eventVerticals,
      expectedGuestCount,
      opsUser
    }
  ] = userEventDetails;

  const [tooltip, setTooltip] = useState({});

  const toggleTooltip = (tooltipId) => {
    setTooltip((prevState) => ({
      ...prevState,
      [tooltipId]: !prevState[tooltipId]
    }));
  };

  const isDummyOrEmptyField = ({ key, value }) =>
    checkIsEmptyOrDummyEventDetail({
      key,
      value
    });

  const sidebarBodyData = [
    {
      className: 'justify-start gap-4 my-1',
      icon: 'zendesk-with-frame.svg',
      iconHeight: 24,
      iconWidth: 24,
      label: parseNumberedLabel(zendeskTicketId),
      onClick: () => {
        zendeskTicketId && handleOnClickZendesk(zendeskTicketId);
      },
      labelStyle: `font-medium cursor-pointer self-center text-dim-gray ${
        zendeskTicketId ? '' : 'text-error-base'
      }`,
      iconSuffix: {
        id: 'zendeskIconTooltip',
        alt: 'hand',
        icon: 'open-icon.svg',
        iconHeight: 24,
        iconStyle: 'ml-auto cursor-pointer',
        iconWidth: 24
      }
    },
    {
      className: 'justify-start gap-4 my-1',
      icon: 'user-red-with-frame.svg',
      iconHeight: 24,
      iconWidth: 24,
      label: eventContactName || 'NA',
      labelStyle: `font-medium self-center text-dim-gray ${
        isDummyOrEmptyField({
          key: 'eventContactName',
          value: eventContactName
        })
          ? 'text-error-base'
          : ''
      }`,
      iconSuffix: {
        id: 'eventContactTooltip',
        alt: 'hand',
        icon: 'view-icon-with-frame.svg',
        iconHeight: 24,
        iconStyle: 'suffixIconStyle ml-auto cursor-pointer',
        iconWidth: 24
      },
      onClick: () => {
        router.push(
          getPageURL({
            excludeBaseUrl: false,
            pageName: PAGE_NAME.HOST_EVENT_LIST.label,
            pathParams: {
              userId
            }
          })
        );
      }
    },
    {
      className: 'justify-start gap-4 my-1',
      icon: 'calendar-red-with-frame.svg',
      iconHeight: 24,
      iconWidth: 24,
      label: eventDate
        ? `${convertToShortMonthDateFormat(eventDate)}, ${formatTimeToAmPm(eventStartTime)}`
        : 'NA',
      labelStyle: `font-medium self-center text-dim-gray ${
        isDummyOrEmptyField({
          key: 'eventDate',
          value: formatDateToHaflaStandardFormat(eventDate)
        })
          ? 'text-error-base'
          : ''
      }`
    },
    {
      className: 'justify-start gap-4 my-1',
      icon: 'location-icon-with-frame.svg',
      iconHeight: 24,
      iconWidth: 24,
      label: eventAddress?.formattedAddress || 'NA',
      labelStyle: `font-medium self-center text-dim-gray ${
        isDummyOrEmptyField({
          key: 'formattedAddress',
          value: eventAddress?.formattedAddress
        })
          ? 'text-error-base'
          : ''
      }`
    },
    {
      className: 'justify-start gap-4 my-1',
      icon: 'verticals-icon-red.svg',
      iconHeight: 24,
      iconWidth: 24,
      label:
        eventVerticals.length > 0
          ? eventVerticals.map(({ name }) => name).join(', ')
          : 'NA',
      labelStyle: `font-medium self-center text-dim-gray`
    },
    {
      className: 'justify-start gap-4 my-1',
      icon: 'guest-icon-red.svg',
      iconHeight: 24,
      iconWidth: 24,
      label: expectedGuestCount ?? 'NA',
      labelStyle: `font-medium self-center text-dim-gray`
    }
  ];

  const toolTipConfigList = [
    {
      target: 'eventContactTooltip',
      text: 'Host'
    },
    {
      target: 'zendeskIconTooltip',
      text: 'Ticket'
    }
  ];
  return (
    <Fragment>
      <PlannerAndAllocationStatusInfo {...{ allocationStatus, opsUser }} />
      {sidebarBodyData.map((props, index) => (
        <TextWithIcon
          key={index}
          {...props}
        />
      ))}
      {toolTipConfigList.map(({ target, text }) => (
        <Tooltip
          innerClassName='bg-black text-white text-xs p-1.5 px-2 rounded mt-1 mr-3'
          key={target}
          placement='bottom'
          isOpen={tooltip[target] || false}
          target={target}
          toggle={() => toggleTooltip(target)}
        >
          {text}
        </Tooltip>
      ))}
    </Fragment>
  );
};

const EventCartListSidebarOrganism = ({ setShowToast, userEvent }) => {
  const router = useRouter();

  const [isMobile] = useIsMobile();

  const displayUserEventProfile = () => {
    router.push(
      getPageURL({
        excludeBaseUrl: false,
        pageName: PAGE_NAME.HOST_EVENT_PROFILE.label,
        pathParams: {
          userEventId: userEvent.userEventDetails[0].userEventId
        }
      })
    );
  };

  return (
    <div className='md:w-108 bg-white px-6 py-4 md:pl-10 md:pr-10 md:pt-5 flex flex-col gap-3'>
      <SideBarHead
        {...{
          displayUserEventProfile,
          isMobile,
          userEvent
        }}
      />

      <SideBarBody
        {...{
          router,
          userEvent
        }}
      />
      <div className='mt-2 mb-4 border-t border-gray'></div>
      <EPICBrief
        {...{
          setShowToast,
          userEvent
        }}
      />
    </div>
  );
};

export default EventCartListSidebarOrganism;
