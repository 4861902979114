import _ from 'lodash';
import { useState } from 'react';

import {
  ActionModal,
  CartCardBody,
  CartCardHeader,
  PDFDownloadConfirmationModal
} from '@/components/atomic/atoms';
import { BID_STATUS } from '@/config/bid';
import { ACTION_MODAL_SOURCE, CART_STATUS } from '@/config/common';
import copyToClipBoard from '@/lib/copyToClipBoard';
import {
  cloneCartToUserEvent,
  verifyEventNumberExistence
} from '@/services/eventCartDetail.service';
import { getWebQuotePageURL } from '@/services/hostPortal.service';
import { downloadQuotationPDF } from '@/services/invoiceQuotationCommon.service';
import { getDispatchCTALabel } from '@/services/tender.service';

const CartCard = ({
  cartCard,
  cartListType,
  isArchivedCart,
  router,
  setEditCartSummaryModal,
  setLoading,
  setShowToast,
  setUserEventCartDetails,
  showCreateTenderAndDispatchBidsCTA,
  userEventId,
  userInfo
} = {}) => {
  const {
    cartName,
    cartNumber,
    cartOrder,
    createdAt,
    deliveryDate,
    id,
    opsUser,
    pickupDate,
    status,
    tenders,
    updatedAt
  } = cartCard;
  const userId = userInfo?.id;
  const dispatchCTALabel = getDispatchCTALabel({ tenders });
  const cartCreatedBy = opsUser?.name || 'NA';

  const [
    showPDFDownloadConfirmationModal,
    setShowPDFDownloadConfirmationModal
  ] = useState(false);

  const [openCloneCartModal, setOpenCloneCartModal] = useState(null);

  const onClickCopyQuoteLink = (e) => {
    e.stopPropagation();
    copyToClipBoard(getWebQuotePageURL({ cartId: id }));
    setShowPDFDownloadConfirmationModal(false);
    setShowToast({
      show: true,
      status,
      message: 'Web Quote Link Copied to ClipBoard'
    });
  };

  const onClickDownloadQuotationPDF = ({ isProforma }) => {
    setShowPDFDownloadConfirmationModal(false);
    downloadQuotationPDF({
      cartId: id,
      isProforma,
      isStandardTypeSelected: true,
      userCartId: userInfo?.id
    });
  };

  const isActiveTenderAvailable = _.some(tenders, {
    status: BID_STATUS.ACTIVE.value
  });

  const isCartInOrderState = status === CART_STATUS.ORDER.value;

  return (
    <div
      className={`py-3 my-5 md:border text-sm md:text-base border-[#F3F3F6] rounded-lg px-4 md:px-3 bg-lightgray font-medium hover:border-cinnabar shadow-event cursor-pointer relative`}
    >
      <CartCardHeader
        {...{
          cartName,
          cartNumber,
          cartOrder,
          status
        }}
      />
      <CartCardBody
        {...{
          cartCreatedBy,
          cartListType,
          cartName,
          cartNumber,
          cartOrder,
          createdAt,
          deliveryDate,
          dispatchCTALabel,
          id,
          isActiveTenderAvailable,
          isArchivedCart,
          isCartInOrderState,
          onClickCopyQuoteLink,
          pickupDate,
          router,
          setEditCartSummaryModal,
          setLoading,
          setOpenCloneCartModal,
          setShowPDFDownloadConfirmationModal,
          setShowToast,
          setUserEventCartDetails,
          showCreateTenderAndDispatchBidsCTA,
          status,
          updatedAt,
          userEventId,
          userId,
          userInfo
        }}
      />
      {showPDFDownloadConfirmationModal && (
        <PDFDownloadConfirmationModal
          {...{
            onClickCopyQuoteLink,
            onClickDownloadQuotationPDF,
            setShowPDFDownloadConfirmationModal,
            showPDFDownloadConfirmationModal
          }}
        />
      )}
      <ActionModal
        {...{
          actionModalRequest: openCloneCartModal,
          ctaLabel: 'Export cart',
          entityNameKey: 'eventName',
          onClickVerify: verifyEventNumberExistence,
          onSubmitRequestConfig: {
            parameters: {
              cartNumber,
              setShowToast
            },
            routine: cloneCartToUserEvent
          },
          placeholder: 'Enter Event Number',
          setActionModalRequest: setOpenCloneCartModal,
          source: ACTION_MODAL_SOURCE.EXPORT_CART,
          title: 'Enter Event Number to Export Cart'
        }}
      />
    </div>
  );
};

export default CartCard;
