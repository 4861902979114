// TODO: Hardik to review
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';

import { ProductImageGalleryModal } from '@/components/atomic/atoms';
import { Button, Text, TextKeyValuePair } from '@/components/atomic/nuclei';
import { useUIContext } from '@/components/context/UiContext/ManagedUiContext';
import {
  isProduction,
  LINE_ITEM_SOURCE,
  staticMediaStoreBaseURL
} from '@/config/common';
import { getImageURL, itemMediaListMapper } from '@/helpers/carousel';
import {
  getFloatStringToTwoDecimalPlaces,
  parseFormatPriceNumberValueFromAPI
} from '@/lib/numberStringUtils';
import { getProductDetailPageURL } from '@/services/hostPortal.service';
import { noProductImageURL } from '@/services/plannerPortal.service';
import { addToCart } from '@/services/product.service';

const updateImageTitle = (image) => ({
  ...image,
  imageTitle: 'Existing Media'
});

const ProductImage = ({
  imageContainerClassName = '',
  imageClassName = 'rounded-t-xl object-fit-cover',
  item,
  openImageGalleryModal,
  setOpenImageGalleryModal
}) => {
  const itemsRaw = itemMediaListMapper({
    ...item,
    source: LINE_ITEM_SOURCE.CART.value
  });

  const itemMediaForCarousel = itemsRaw.itemMediaForCarousel.map((image) =>
    updateImageTitle(image)
  );
  const [imageURL, setImageURL] = useState(
    getImageURL(itemMediaForCarousel[0])
  );
  return (
    <div className='product-image clickable'>
      <div className={`relative ${imageContainerClassName}`}>
        <Image
          {...{
            alt: item.product.name,
            className: imageClassName,
            fill: true,
            onError: () => setImageURL(noProductImageURL),
            priority: true,
            sizes: '(max-width: 600px) 100vw, 50vw',
            src: imageURL
          }}
        />
      </div>
      {openImageGalleryModal && (
        <ProductImageGalleryModal
          {...{
            itemMediaForCarousel,
            productName: itemsRaw.product.name,
            setOpenImageGalleryModal
          }}
        />
      )}
    </div>
  );
};

const ImageWithLabelValuePair = ({ alt, className, icon, label, value }) => (
  <div className={className}>
    <div className='self-center'>
      <Image
        {...{
          alt,
          className: 'rounded-lg',
          height: 0,
          src: `${staticMediaStoreBaseURL}/icons/${icon}`,
          style: { height: 44, width: 44 },
          width: 0
        }}
      />
    </div>
    <TextKeyValuePair
      {...{
        className: 'flex gap-0 flex-col self-center',
        label: value || '',
        labelClass: 'text-lg font-semibold text-brand',
        value: label,
        valueClassName: 'text-dim-gray font-medium text-sm'
      }}
    />
  </div>
);

const ViewMoreProductDetail = ({
  averageUnitPartnerPrice,
  averageUnitSellingPrice,
  flipProductCardAndViewMoreDetails,
  hit,
  openImageGalleryModal,
  setOpenImageGalleryModal,
  slug,
  userEventCount
}) => {
  const [moreDetailIcon, setMoreDetailIcon] = useState('modal-open-icon.svg');
  const imageWithLabelValuePairList = [
    {
      alt: 'event-count-image',
      className:
        'flex gap-4 statistics-card h-4.5 rounded-lg p-3 bg-product-button bg-no-repeat bg-right-top',
      icon: 'order-icon-with-frame.svg',
      label: 'User Events',
      value: userEventCount
    },
    {
      alt: 'hafla-price-image',
      className:
        'flex gap-4 statistics-card h-4.5 rounded-lg p-3 bg-product-price-bg-bottom bg-no-repeat bg-right-bottom',
      icon: 'price-icon-with-frame.svg',
      label: 'Hafla Price (Avg)',
      value: getFloatStringToTwoDecimalPlaces(averageUnitSellingPrice)
    },
    {
      alt: 'hafla-price-image',
      className:
        'flex gap-4 statistics-card h-4.5 rounded-lg p-3 bg-product-price-bg-left bg-no-repeat bg-right',
      icon: 'price-icon-with-frame.svg',
      label: 'Partner Price (Avg)',
      value: getFloatStringToTwoDecimalPlaces(averageUnitPartnerPrice)
    }
  ];

  return (
    <div
      className={` flex flex-col gap-3 open-more-detail-product-btn rounded-[12px] pt-1 ${flipProductCardAndViewMoreDetails ? 'flex flip-card-back' : 'hidden'}`}
    >
      <div
        className='mt-1 relative -left-4 h-10 w-14 border border-platinum rounded-md'
        onClick={(e) => {
          e.stopPropagation();
          setOpenImageGalleryModal(true);
        }}
      >
        <ProductImage
          {...{
            imageContainerClassName: 'h-10 product-thumb-image',
            imageClassName: 'rounded-md',
            item: { product: { ...hit } },
            openImageGalleryModal,
            setOpenImageGalleryModal
          }}
        />
      </div>
      <div className='flex flex-col gap-3'>
        {imageWithLabelValuePairList.map(
          (imageWithLabelValuePairProps, index) => (
            <ImageWithLabelValuePair
              key={index}
              {...imageWithLabelValuePairProps}
            />
          )
        )}
      </div>
      <Link
        target='_blank'
        href={getProductDetailPageURL({ slug })}
      >
        <Button
          {...{
            className:
              'bg-brand-gradient text-white rounded-full text-base font-medium py-2 product-more-detail-btn hover:!bg-white hover:!text-brand',
            iconHeight: 20,
            iconPosition: 'Left',
            iconURL: `${staticMediaStoreBaseURL}/icons/${moreDetailIcon}`,
            iconWidth: 20,
            label: 'More details',
            onMouseEnter: () => {
              setMoreDetailIcon('modal-open-icon-red.svg');
            },
            onMouseLeave: () => {
              setMoreDetailIcon('modal-open-icon.svg');
            },
            width: 'w-40 mx-auto mt-3'
          }}
        />
      </Link>
    </div>
  );
};

const ProductCardHeader = ({
  flipProductCardAndViewMoreDetails,
  hit,
  openImageGalleryModal,
  relevanceScore,
  setOpenImageGalleryModal,
  showRelevanceScore
}) => (
  <div>
    <div className='rounded-lg relative'>
      <div className='absolute top-2 left-2 z-[2]'>
        <Image
          {...{
            alt: 'view all images',
            className: 'cursor-pointer',
            height: 0,
            onClick: (e) => {
              e.stopPropagation();
              setOpenImageGalleryModal(true);
            },
            priority: true,
            src: `${staticMediaStoreBaseURL}/icons/resize-icon.svg`,
            style: {
              height: flipProductCardAndViewMoreDetails ? 24 : 28,
              width: flipProductCardAndViewMoreDetails ? 24 : 28
            },
            width: 0
          }}
        />
      </div>
      {showRelevanceScore && (
        <Text
          {...{
            content: Math.round(relevanceScore * 100),
            className:
              'text-xs text-white font-medium mt-2 max-w-80 truncate absolute z-[2] left-full -ml-11 bg-brand-gradient rounded px-1 py-[2px]'
          }}
        />
      )}
    </div>
    <div className='display-row'>
      <ProductImage
        {...{
          item: { product: { ...hit } },
          openImageGalleryModal,
          setOpenImageGalleryModal,
          imageContainerClassName: 'w-full h-56 relative rounded-l-xl'
        }}
      />
    </div>
  </div>
);

const ProductCardSummary = ({
  discount,
  name,
  partnerName,
  price,
  priceBasedOnProductTypeMap,
  priceId,
  productId,
  setShowToast,
  setAddItemToNewCartRequest,
  productPartners,
  shortDescription,
  showDiscount,
  type,
  unitPartnerPrice
}) => {
  const { activePlannerCart, updateActivePlannerCart } = useUIContext();

  return (
    <div className='flex flex-col p-3'>
      <Text
        {...{ content: name, className: 'font-medium max-w-80 truncate' }}
      />
      <Text
        {...{
          content: shortDescription,
          className: 'text-xs text-brand font-medium my-1 max-w-80 truncate'
        }}
      />
      <div className='flex gap-2 font-medium'>
        <Text
          {...{
            content: priceBasedOnProductTypeMap[type],
            className: 'text-nero text-sm'
          }}
        />
        {showDiscount && (
          <>
            <Text
              {...{
                content: `${parseFormatPriceNumberValueFromAPI(price)} AED`,
                className: 'text-dim-gray text-xs leading-[19px] line-through'
              }}
            />
            <Text
              {...{
                content: `${discount}% off`,
                className: 'text-green text-sm'
              }}
            />
          </>
        )}
      </div>
      <div className='my-3 border-t' />
      <div className='flex flex-col gap-2 text-sm text-dim-gray font-medium'>
        <div className='flex gap-1'>
          <Image
            alt='user-image'
            className='rounded-lg'
            height={0}
            src={`${staticMediaStoreBaseURL}/icons/user-icon.svg`}
            style={{ height: 18, width: 18 }}
            width={0}
          />
          <Text
            {...{
              content: partnerName,
              className: 'max-w-44 truncate'
            }}
          />
          {productPartners.length > 1 && (
            <Text
              {...{
                content: ` +${productPartners.length - 1} more`,
                className:
                  'text-brand bg-base-white shadow text-xs font-medium px-2 py-[2px] self-center flex ml-2 rounded'
              }}
            />
          )}
        </div>
        <div className='flex gap-1'>
          <Image
            alt='price-icon'
            className='rounded-lg'
            height={0}
            src={`${staticMediaStoreBaseURL}/icons/price-icon.svg`}
            style={{ height: 18, width: 18 }}
            width={0}
          />
          <Text
            {...{
              content: `AED ${parseFormatPriceNumberValueFromAPI(unitPartnerPrice)}`,
              className: 'max-w-72 truncate'
            }}
          />
        </div>
        <Button
          {...{
            className:
              'bg-brand-gradient text-white font-medium py-2 rounded text-sm add-to-cart-btn text-center',
            iconGroupClassName: 'gap-2',
            iconHeight: 16,
            iconPosition: 'Left',
            iconURL: `${staticMediaStoreBaseURL}/icons/cart.svg`,
            iconWidth: 16,
            label: 'Add to Cart',
            onClick: (e) => {
              e.stopPropagation();
              const productDetail = {
                entityId: productId,
                entityType: type,
                quantity: 1,
                priceId
              };
              if (activePlannerCart) {
                addToCart({
                  activePlannerCart,
                  productDetail,
                  setShowToast,
                  updateActivePlannerCart
                });
              } else {
                setAddItemToNewCartRequest(productDetail);
              }
            }
          }}
        />
      </div>
    </div>
  );
};

const ProductCardDetail = ({
  discount,
  flipProductCardAndViewMoreDetails,
  hit,
  name,
  openImageGalleryModal,
  partnerName,
  price,
  priceBasedOnProductTypeMap,
  priceId,
  productId,
  productPartners,
  relevanceScore,
  setOpenImageGalleryModal,
  setAddItemToNewCartRequest,
  setShowToast,
  shortDescription,
  showDiscount,
  showRelevanceScore,
  type,
  unitPartnerPrice
}) => (
  <div className='flip-card-front'>
    <ProductCardHeader
      {...{
        flipProductCardAndViewMoreDetails,
        hit,
        openImageGalleryModal,
        relevanceScore,
        setOpenImageGalleryModal,
        showRelevanceScore
      }}
    />
    <ProductCardSummary
      {...{
        discount,
        name,
        partnerName,
        price,
        priceBasedOnProductTypeMap,
        priceId,
        productId,
        productPartners,
        setAddItemToNewCartRequest,
        setShowToast,
        shortDescription,
        showDiscount,
        type,
        unitPartnerPrice
      }}
    />
  </div>
);

const ProductCard = ({
  hit,
  openedCardId,
  setOpenedCardId,
  setAddItemToNewCartRequest,
  setShowToast
}) => {
  const {
    _score: relevanceScore,
    discount,
    isDiscountAvailable,
    name,
    price: { id: priceId, price, minPrice, priceToApply },
    productPartners,
    shortDescription,
    type,
    productOrderStatistics: {
      averageUnitPartnerPrice,
      averageUnitSellingPrice,
      userEventCount
    },
    id: productId,
    slug
  } = hit;

  const [
    {
      unitPartnerPrice,
      partner: { legalName: partnerName }
    }
  ] = productPartners;

  const [openImageGalleryModal, setOpenImageGalleryModal] = useState(false);

  const priceBasedOnProductTypeMap = {
    Cart: `AED ${parseFormatPriceNumberValueFromAPI(priceToApply)}`,
    Quote: `Price starting from AED ${parseFormatPriceNumberValueFromAPI(
      minPrice
    )}`
  };

  const showDiscount = type === 'Cart' && isDiscountAvailable;
  const flipProductCardAndViewMoreDetails = openedCardId === hit.id;
  const showRelevanceScore = !isProduction && relevanceScore;

  return (
    <div
      className={`flip-card ${flipProductCardAndViewMoreDetails ? 'hover fade-in' : ''}`}
    >
      <div
        className='flip-card-inner flex flex-col relative w-80 border cursor-pointer rounded-[12px] hover:border-brand hover:shadow-product-card'
        onClick={() =>
          setOpenedCardId((prevId) => (prevId === hit.id ? null : hit.id))
        }
      >
        <ProductCardDetail
          {...{
            discount,
            flipProductCardAndViewMoreDetails,
            hit,
            name,
            openImageGalleryModal,
            partnerName,
            price,
            priceBasedOnProductTypeMap,
            priceId,
            productId,
            productPartners,
            relevanceScore,
            setOpenImageGalleryModal,
            setAddItemToNewCartRequest,
            setShowToast,
            shortDescription,
            showDiscount,
            showRelevanceScore,
            type,
            unitPartnerPrice
          }}
        />
        <ViewMoreProductDetail
          {...{
            averageUnitPartnerPrice,
            averageUnitSellingPrice,
            flipProductCardAndViewMoreDetails,
            hit,
            openImageGalleryModal,
            setOpenImageGalleryModal,
            slug,
            userEventCount
          }}
        />
      </div>
    </div>
  );
};

export default ProductCard;
