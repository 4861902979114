export const enumEventFor = [
  { name: 'B2B', id: 'B2B' },
  { name: 'Family', id: 'Family' },
  { name: 'Friends', id: 'Friends' },
  {
    name: 'Planning for someone else (ex: planner)',
    id: 'Planning for someone else'
  },
  { name: 'Self', id: 'Self' }
];

export const userAddressObject = {
  formattedAddress: '',
  houseNumber: '',
  buildingName: '',
  landmark: '',
  latitude: 0,
  longitude: 0,
  locationSelected: false,
  cityId: ''
};

export const zendeskDetailsArray = [
  { label: 'Hafla Request Vertical(s)', dbKeyName: 'haflaRequestVertical' },
  { label: 'Submission Date', dbKeyName: 'submissionDate' },
  { label: 'NER to Inquiry Status', dbKeyName: 'inquiryGenerationStatus' },
  { label: 'Inquiry Number', dbKeyName: 'inquiryNumber' },
  { label: 'Inquiry to Quote Status', dbKeyName: 'inquiryToQuoteStatus' },
  { label: 'Quote Number', dbKeyName: 'quoteNumber' },
  { label: 'Quote to Closure Status', dbKeyName: 'quoteToClosureStatus' },
  { label: 'Total quoted GEV (AED)', dbKeyName: 'totalGEVQuoted' }
];
